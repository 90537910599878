import { SharedDataProvider } from '../providers/SharedDataProvider'
import 'regenerator-runtime'

export class TermsController {
  constructor() {}

  public getTerm(id, selectedLanguage) {
    const companyTermsString =
      window.localStorage.getItem('loggedInUserCompanyTerms') || '[]'
    const companyTerms = JSON.parse(companyTermsString)
    const termFromStorage = companyTerms
      .find((term) => term.id === id)
      ?.translations.find((t) => t.lang === selectedLanguage)?.term

    return termFromStorage || null // Falls kein Begriff gefunden wird, gib `null` zurück
  }
}
