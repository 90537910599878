import { SharedDataProvider } from '../../../../shared/providers/SharedDataProvider'
import 'regenerator-runtime'
import { AuthenticationController } from '../../../../shared/controllers/AuthenticationController'
import { RequestController } from '../../../../shared/controllers/RequestController'

export class CompanySearchController {
  sharedDataProvider: SharedDataProvider
  requestController: RequestController

  constructor(history) {
    this.sharedDataProvider = new SharedDataProvider()
    this.requestController = new RequestController(history)
  }

  public async searchForCompanies(
    searchValue,
    searchProperty,
    searchIndexFrom,
    searchIndexTo
  ) {
    let path =
      this.sharedDataProvider.host +
      '/companies/' +
      this.getRoute(searchProperty) +
      '?' +
      searchProperty +
      '=' +
      searchValue +
      '&searchIndexFrom=' +
      searchIndexFrom +
      '&searchIndexTo=' +
      searchIndexTo
    let data = await this.requestController.getRequest(path)
    return data
  }

  private getRoute(searchProperty) {
    if (searchProperty === 'name') {
      return 'getCompaniesByName'
    }
  }
}
