import { User } from '../../users/models/User'

interface IRole {
  id: string
  name: string
}

interface IUnlockLevel {
  id: string
  name: string
}

export class SharedDataProvider {
  host: string
  roles: Array<IRole>
  unlockLevels: Array<IUnlockLevel>

  constructor() {
    // PROD
    this.host = '/api'
    // DEV
    // this.host = 'http://localhost:3001/api'

    this.roles = [
      {
        id: 'admin',
        name: 'Admin',
      },
      {
        id: 'manufacturerAdmin',
        name: 'Hersteller Admin',
      },
      {
        id: 'manufacturerAdminLight',
        name: 'Hersteller Admin Light',
      },
      {
        id: 'technician',
        name: 'Techniker',
      },
      {
        id: 'manufacturerCustomerWithMachineAccess',
        name: 'Hersteller Kunde mit Maschinenzugriff',
      },
      {
        id: 'manufacturerCustomer',
        name: 'Hersteller Kunde',
      },
    ]

    this.unlockLevels = [
      {
        id: '0',
        name: '0',
      },
      {
        id: '1',
        name: '1',
      },
      {
        id: '2',
        name: '2',
      },
      {
        id: '3',
        name: '3',
      },
      {
        id: '4',
        name: '4',
      },
    ]
  }

  public getRoleName(role): string {
    for (let i = 0; i < this.roles.length; i++) {
      if (this.roles[i].id === role) {
        return this.roles[i].name
      }
    }
    return 'ROLE NOT FOUND'
  }

  public getAccessibleRoles(user: User): IRole[] {
    let userRole = window.localStorage.getItem('loggedInUserRole')
    if (userRole === 'admin') {
      // admin can see all roles
      return this.roles
    } else if (userRole === 'manufacturerAdmin') {
      // manufacturer admin cannot see admin role
      return this.roles.slice(1)
    } else if (userRole === 'manufacturerAdminLight') {
      // manufacturer admin light can see manufacturer admin role only if the user is already manufacturer admin
      // as the manufacturer admin light cannot edit manufacturer admins
      if (user.role === 'manufacturerAdmin') {
        return this.roles.slice(1)
      } else {
        // but madnufacturer admin light cannot see admin and manufacturer admin roles for users that are editable for him
        return this.roles.slice(2)
      }
    } else {
      return []
    }
  }

  public getAccessibleUnlockLevels(): IUnlockLevel[] {
    return this.unlockLevels
    /*
    let unlockLevelString = window.localStorage.getItem(
      'loggedInUserUnlockLevel'
    )

    console.log('######')
    console.log(unlockLevelString)

    // If the localStorage item does not exist or is not a number, default to 0
    let unlockLevel = unlockLevelString ? parseInt(unlockLevelString) : 0

    // If the parsing fails (returns NaN), also default to 0
    if (isNaN(unlockLevel)) {
      unlockLevel = 0
    }

    // Increment the unlockLevel to get the correct slice of array
    // (as index in JavaScript arrays start from 0, while unlockLevel starts from 1)
    unlockLevel++

    // Return the sliced array
    return this.unlockLevels.slice(0, unlockLevel)
    */
  }
}
