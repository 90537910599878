import { Button, createStyles, makeStyles, Theme } from '@material-ui/core'
import { SelectAllRounded } from '@material-ui/icons'
import React, { useEffect, useState } from 'react'
import { Footer } from '../../../../../shared/components/Footer'
import { Header } from '../../../../../shared/components/Header'
import { LoadingAnimation } from '../../../../../shared/components/LoadingAnimation'
import { SharedDataProvider } from '../../../../../shared/providers/SharedDataProvider'
import { Company, emptyCompany } from '../../../../models/Company'
import { Machine } from '../../../../models/Machine'
import { emptyMachineType, MachineType } from '../../../../models/MachineType'
import { MachineSearchController } from '../../controllers/MachineSearchController'
import { MachineSearchResult } from './MachineSearchResult'
import { SearchBar } from './SearchBar'

export const Machines = (props) => {
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      appContent: {
        minHeight: '86vh',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        flexWrap: 'wrap',
        alignItems: 'center',
      },
      seperator: {
        borderBottom: '1px solid lightgrey',
        marginTop: '10px',
        marginBottom: '10px',
      },
      dataContainer: {
        marginTop: 30,
        marginLeft: 20,
        marginRight: 20,
      },
      textfield: {
        margin: 0,
        width: 200,
        backgroundColor: 'white',
      },
      button: {
        width: 100,
        borderRadius: '10px',
        backgroundColor: '#1c8033',
        color: 'white',
        variant: 'contained',
        margin: 5,
        fontSize: 15,
        '&:hover': {
          backgroundColor: '#1c8033',
        },
      },
      buttonLoadMore: {
        backgroundColor: '#ffffff',
        color: 'black',
        variant: 'contained',
        padding: 10,
        marginTop: 10,
        '&:hover': {
          backgroundColor: '#eeeeee',
        },
      },
      searchResultMainData: {
        color: '#212121',
        fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
        fontSize: 16,
        textAlign: 'left',
      },
      companyAndMachineTypeContainer: {
        display: 'flex',
        justifyContent: 'center',
      },
      companyAndMachineTypeDisplay: {
        display: 'flex',
        flexDirection: 'column',
        marginRight: 20,
        marginLeft: 20,
      },
      searchResults: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'flex-start',
        marginBottom: 20,
      },
      companyLogo: {
        objectFit: 'contain',
        objectPosition: 'center',
        height: '120px',
        width: '180px',
      },
      machinePicture: {
        objectFit: 'contain',
        objectPosition: 'center',
        height: '120px',
        borderRadius: '50%',
      },
    })
  )
  const classes = useStyles()

  const machineSearchController: MachineSearchController = new MachineSearchController(
    props.history
  )
  const sharedDataProvider = new SharedDataProvider()

  const [companyId, setCompanyId] = useState('')
  const [machineTypeId, setMachineTypeId] = useState('')
  const [machineType, setMachineType] = useState<MachineType>(emptyMachineType)
  const [machines, setMachines] = useState<Machine[]>([])

  const [isLoading, setIsLoading] = useState(true)

  const [latestSearchValue, setLatestSearchValue] = useState('')
  const [latestSearchProperty, setLatestSearchProperty] = useState('')
  let [searchIndexFrom, setSearchIndexFrom] = React.useState(0)
  let [searchIndexTo, setSearchIndexTo] = React.useState(20)
  let [
    totalNumberOfSearchResults,
    setTotalNumberOfSearchResults,
  ] = React.useState(0)

  async function getBatchOfMachines(
    searchValue: string,
    searchProperty: string,
    newSearch: Boolean
  ) {
    if (newSearch === true) {
      setIsLoading(true)
      let searchResult = await machineSearchController.searchForMachines(
        machineTypeId,
        searchValue,
        searchProperty,
        0,
        20
      )

      setSearchIndexFrom(0)
      setSearchIndexTo(20)

      let machines = searchResult.machines
      let totalNumberOfSearchResults = searchResult.totalNumberOfSearchResults

      setMachines(machines)
      setTotalNumberOfSearchResults(totalNumberOfSearchResults)
      setLatestSearchValue(searchValue)
      setLatestSearchProperty(searchProperty)
    } else {
      let searchResult = await machineSearchController.searchForMachines(
        machineTypeId,
        searchValue,
        searchProperty,
        searchIndexFrom + 20,
        searchIndexTo + 20
      )

      setSearchIndexFrom(searchIndexFrom + 20)
      setSearchIndexTo(searchIndexTo + 20)

      let nextMachines = searchResult.machines

      setMachines([...machines, ...nextMachines])
    }

    setIsLoading(false)
  }

  useEffect(() => {
    const { params } = props.match
    setCompanyId(params.companyId)
    setMachineTypeId(params.machineTypeId)

    let getCompanyAndMachineTypeById = async () => {
      let machineType: MachineType = await machineSearchController.getMachineTypeById(
        params.machineTypeId
      )
      setMachineType({ ...machineType })
    }
    getCompanyAndMachineTypeById()
  }, [])

  useEffect(() => {
    let getAllMachines = async () => {
      getBatchOfMachines('', 'machineNumber', true)
    }
    getAllMachines()
  }, [machineTypeId])

  return (
    <div>
      <Header
        page={'machines'}
        history={props.history}
        selectedLanguage={props.selectedLanguage}
        setSelectedLanguage={props.setSelectedLanguage}
      />

      <div className={classes.appContent}>
        <div className={classes.dataContainer}>
          <SearchBar
            getBatchOfMachines={getBatchOfMachines}
            companyId={companyId}
            machineTypeId={machineTypeId}
            history={props.history}
            selectedLanguage={props.selectedLanguage}
          />
        </div>

        {isLoading ? (
          <div className={classes.dataContainer}>
            <LoadingAnimation />
          </div>
        ) : (
          <div className={classes.dataContainer}>
            <div className={classes.companyAndMachineTypeContainer}>
              <div className={classes.companyAndMachineTypeDisplay}>
                <img
                  className={classes.companyLogo}
                  src={
                    sharedDataProvider.host +
                    '/companies/getCompanyLogo?id=' +
                    companyId +
                    '&' +
                    new Date()
                  }
                ></img>
              </div>
              <div className={classes.companyAndMachineTypeDisplay}>
                <img
                  className={classes.machinePicture}
                  src={
                    sharedDataProvider.host +
                    '/machineTypes/getMachineTypePicture?id=' +
                    machineType._id +
                    '&' +
                    new Date()
                  }
                ></img>
                {machineType.name}
              </div>
            </div>
            <div className={classes.dataContainer}>
              <div className={classes.searchResultMainData}>
                {totalNumberOfSearchResults}
                {
                  {
                    de: ' Ergebnisse',
                    en: ' results',
                    fr: ' Ergebnisse - FR',
                  }[props.selectedLanguage]
                }
              </div>
            </div>
            <div className={classes.seperator}></div>
            <div className={classes.dataContainer}>
              <div className={classes.searchResults}>
                {machines.map((machine) => (
                  <MachineSearchResult machine={machine} />
                ))}

                {searchIndexTo < totalNumberOfSearchResults ? (
                  <Button
                    className={classes.buttonLoadMore}
                    onClick={() => {
                      getBatchOfMachines(
                        latestSearchValue,
                        latestSearchProperty,
                        false
                      )
                    }}
                  >
                    {
                      {
                        de: 'mehr laden',
                        en: 'load more',
                        fr: 'mehr laden - FR',
                      }[props.selectedLanguage]
                    }
                  </Button>
                ) : null}
              </div>
            </div>
          </div>
        )}
      </div>

      <Footer
        history={props.history}
        selectedLanguage={props.selectedLanguage}
      />
    </div>
  )
}
