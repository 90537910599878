import { SharedDataProvider } from '../providers/SharedDataProvider'

export class ImageController {
  sharedDataProvider: SharedDataProvider

  constructor() {
    this.sharedDataProvider = new SharedDataProvider()
  }

  public prepareImageForUpload(file: File): Promise<Blob> {
    return new Promise((resolve, reject) => {
      let image = new Image()
      image.src = URL.createObjectURL(file)

      image.onload = () => {
        // calculation - crop and scale the image to fit the format 3:2
        let largestSide = 800

        let sw = image.width
        let sh = image.height

        let rw = sw / largestSide
        let rh = sh / largestSide

        let mult = rw > rh ? rw : rh

        let dw = sw / mult
        let dh = sh / mult

        // -----------

        let canvas = document.createElement('canvas')
        canvas.width = dw
        canvas.height = dh

        let context = canvas.getContext('2d')
        context.drawImage(image, 0, 0, sw, sh, 0, 0, dw, dh)
        canvas.toBlob(resolve, file.type)
      }
      image.onerror = reject
    })
  }

  public prepareImageForUploadSquared(file: File): Promise<Blob> {
    return new Promise((resolve, reject) => {
      let image = new Image()
      image.src = URL.createObjectURL(file)

      image.onload = () => {
        // calculation - crop the image to fit the format 1:1 (squared)
        let sw = image.width
        let sh = image.height

        let shortestSide = sw < sh ? sw : sh

        //let rw = sw / largestSide
        //let rh = sh / largestSide

        //let mult = rw > rh ? rw : rh

        // scale the image to have a width and height of 800
        let dw = 800
        let dh = 800

        let dx = sw === shortestSide ? (sw - shortestSide) / 2 : 0
        let dy = sw === shortestSide ? (sh - shortestSide) / 2 : 0

        // -----------

        let canvas = document.createElement('canvas')
        canvas.width = dw
        canvas.height = dh

        let context = canvas.getContext('2d')
        //context.drawImage(image, 0, 0, sw, sh, 0, 0, dw, dh)
        //context.drawImage(image, dx, dy, shortestSide, shortestSide)
        context.drawImage(
          image,
          dx,
          dy,
          shortestSide,
          shortestSide,
          0,
          0,
          dw,
          dh
        )
        canvas.toBlob(resolve, file.type)
      }
      image.onerror = reject
    })
  }
}
