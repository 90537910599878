import React from 'react'
import { AppBar, createStyles, makeStyles, Theme } from '@material-ui/core'
import { SharedStyleProvider } from '../providers/SharedStyleProvider'

interface FooterProps {
  history
  selectedLanguage
}

export const Footer = ({ history, selectedLanguage }: FooterProps) => {
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      footer: {
        backgroundColor: '#ffffff',
        position: 'static',
        height: '7vh',
      },
      toolbar: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        height: '100%',
      },
      toolbarText: {
        color: '#000000',
        fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
        fontSize: 20,
        textAlign: 'center',
        marginTop: 'auto',
        marginBottom: 'auto',
        marginRight: '50px',
        cursor: 'pointer',
      },
    })
  )
  const classes = useStyles()

  return (
    <AppBar className={classes.footer}>
      <div className={classes.toolbar}>
        <div
          className={classes.toolbarText}
          onClick={() => history.push('/imprint')}
        >
          {
            {
              de: 'Impressum',
              en: 'Imprint',
              fr: 'Impressum - FR',
            }[selectedLanguage]
          }
        </div>
        <div
          className={classes.toolbarText}
          onClick={() => history.push('/contact')}
        >
          {
            {
              de: 'Kontakt',
              en: 'Contact',
              fr: 'Kontakt - FR',
            }[selectedLanguage]
          }
        </div>
        <div
          className={classes.toolbarText}
          onClick={() => history.push('/privacy')}
        >
          {
            {
              de: 'Datenschutz',
              en: 'Privacy',
              fr: 'Datenschutz - FR',
            }[selectedLanguage]
          }
        </div>
      </div>
    </AppBar>
  )
}
