import { SharedDataProvider } from '../../../../shared/providers/SharedDataProvider'
import 'regenerator-runtime'
import { User } from '../../../models/User'
import { RequestController } from '../../../../shared/controllers/RequestController'
import { ToastController } from '../../../../shared/controllers/ToastController'

export class ChangePasswordController {
  sharedDataProvider: SharedDataProvider
  toastController: ToastController
  requestController: RequestController

  constructor(history) {
    this.sharedDataProvider = new SharedDataProvider()
    this.toastController = new ToastController()
    this.requestController = new RequestController(history)
  }

  public async changePassword(
    oldPassword: string,
    newPassword: string
  ): Promise<User> {
    let path =
      this.sharedDataProvider.host +
      '/users/changePassword?old=' +
      oldPassword +
      '&new=' +
      newPassword
    let data = await this.requestController.getRequest(path)

    // show toast
    this.toastController.showToast_changePassword()

    return data
  }
}
