import React from 'react'
import { Button, createStyles, makeStyles, Theme } from '@material-ui/core'
import { SharedDataProvider } from '../../providers/SharedDataProvider'
import DeleteIcon from '@material-ui/icons/Delete'
import GetAppIcon from '@material-ui/icons/GetApp'
import {
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  Select,
  TextField,
  Menu,
  MenuItem,
} from '@material-ui/core'
import PublishIcon from '@material-ui/icons/Publish'
import EditIcon from '@material-ui/icons/Edit'

interface FileProps {
  file: any
  path: string[]
  setPath: any
  index
  type: string
  history
  selectedLanguage
  openFileInBrowser: any
  downloadFile: any
  downloadPreviewImage: any
  deleteFile: any
  renameFile: any
  showRenameOption: boolean
  showDeleteOption: boolean
}

export const File = ({
  file,
  path,
  setPath,
  index,
  type,
  history,
  selectedLanguage,
  openFileInBrowser,
  downloadFile,
  downloadPreviewImage,
  deleteFile,
  renameFile,
  showRenameOption,
  showDeleteOption,
}: FileProps) => {
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      dataContainer: {
        marginTop: 30,
        marginLeft: 20,
        marginRight: 20,
      },
      buttonDataContainer: {
        display: 'flex',
        alignItems: 'center',
        marginLeft: 'auto',
      },
      backgroundGray: {
        backgroundColor: '#f0f0f0',
      },
      userDataContainer: {
        display: 'flex',
        alignItems: 'center',
        paddingTop: 10,
        paddingBottom: 10,
        paddingLeft: 10,
        paddingRight: 10,
      },
      userText: {
        color: '#212121',
        fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
        fontSize: 16,
        textAlign: 'left',
        marginRight: 10,
      },
      userButton: {},
      textfield: {
        margin: 0,
        width: 200,
        backgroundColor: 'white',
      },
      button: {
        width: 200,
        borderRadius: '10px',
        backgroundColor: '#1c8033',
        color: 'white',
        variant: 'contained',
        margin: 5,
        fontSize: 15,
        '&:hover': {
          backgroundColor: '#1c8033',
        },
      },
      userProfilePicture: {
        objectFit: 'contain',
        objectPosition: 'center',
        width: '75px',
        borderRadius: '50%',
        marginRight: 10,
      },
      fileicon: {
        objectFit: 'contain',
        objectPosition: 'center',
        maxHeight: '30px',
        paddingRight: '10px',
      },
      addUserButton: {
        borderRadius: '10px',
        backgroundColor: '#1c8033',
        color: 'white',
        variant: 'contained',
        margin: 5,
        marginLeft: 'auto',
        fontSize: 15,
        '&:hover': {
          backgroundColor: '#1c8033',
        },
      },
      heading: {
        color: '#212121',
        fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
        fontSize: 18,
        textAlign: 'center',
        marginBottom: 10,
      },
      deleteUserButton: {
        borderRadius: '10px',
        backgroundColor: '#f44336',
        color: 'white',
        variant: 'contained',
        margin: 5,
        marginLeft: 'auto',
        fontSize: 15,
        '&:hover': {
          backgroundColor: '#d32215',
        },
      },
      seperator: {
        borderBottom: '1px solid lightgrey',
        marginTop: '10px',
        marginBottom: '10px',
      },
      actionsButton: {
        backgroundColor: 'white',
        color: 'gray',
        fontSize: 16,
        borderRadius: '5px',
        border: '1px solid lightgray',
        marginLeft: '30px',
        '&:hover': {},
      },
      fileRow: {
        '&:hover': {
          backgroundColor: '#f0f0f0',
        },
        borderBottom: '1px solid lightgray',
      },
      folderRow: {
        '&:hover': {
          backgroundColor: '#f0f0f0',
        },
        borderBottom: '1px solid lightgray',
        cursor: 'pointer',
      },
      td: {
        padding: '0px',
        paddingTop: '5px',
        paddingBottom: '5px',
      },
      label: {
        display: 'flex',
      },
      redMenuItem: {
        color: 'red',
      },
    })
  )
  const classes = useStyles()

  const [showMenu, setShowMenu] = React.useState<null | HTMLElement>(null)

  const openMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setShowMenu(event.currentTarget)
  }
  const closeMenu = () => {
    setShowMenu(null)
  }

  const sharedDataProvider = new SharedDataProvider()

  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return '0 Bytes'
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
  }

  function formatDate(date) {
    return new Date(date).toLocaleDateString('de-DE', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
    })
  }

  const icons = {
    jpg: require('../../../../documents/file-manager-icons/jpg.png'),
    pic: require('../../../../documents/file-manager-icons/pic.png'),
    tif: require('../../../../documents/file-manager-icons/tif.png'),
    Empty_File: require('../../../../documents/file-manager-icons/Empty_File.png'),
    doc: require('../../../../documents/file-manager-icons/doc.png'),
    mov: require('../../../../documents/file-manager-icons/mov.png'),
    png: require('../../../../documents/file-manager-icons/png.png'),
    txt: require('../../../../documents/file-manager-icons/txt.png'),
    ai: require('../../../../documents/file-manager-icons/ai.png'),
    exe: require('../../../../documents/file-manager-icons/exe.png'),
    mp3: require('../../../../documents/file-manager-icons/mp3.png'),
    ppt: require('../../../../documents/file-manager-icons/ppt.png'),
    pptx: require('../../../../documents/file-manager-icons/ppt.png'),
    video: require('../../../../documents/file-manager-icons/video.png'),
    apk: require('../../../../documents/file-manager-icons/apk.png'),
    file: require('../../../../documents/file-manager-icons/file.png'),
    mp4: require('../../../../documents/file-manager-icons/mp4.png'),
    ps: require('../../../../documents/file-manager-icons/ps.png'),
    xd: require('../../../../documents/file-manager-icons/xd.png'),
    avi: require('../../../../documents/file-manager-icons/avi.png'),
    file_Error: require('../../../../documents/file-manager-icons/file_Error.png'),
    mpg: require('../../../../documents/file-manager-icons/mpg.png'),
    rar: require('../../../../documents/file-manager-icons/rar.png'),
    xls: require('../../../../documents/file-manager-icons/xls.png'),
    bmp: require('../../../../documents/file-manager-icons/bmp.png'),
    folder: require('../../../../documents/file-manager-icons/folder.png'),
    musik: require('../../../../documents/file-manager-icons/musik.png'),
    raw: require('../../../../documents/file-manager-icons/raw.png'),
    xml: require('../../../../documents/file-manager-icons/xml.png'),
    css: require('../../../../documents/file-manager-icons/css.png'),
    gif: require('../../../../documents/file-manager-icons/gif.png'),
    pdf: require('../../../../documents/file-manager-icons/pdf.png'),
    sql: require('../../../../documents/file-manager-icons/sql.png'),
    zip: require('../../../../documents/file-manager-icons/zip.png'),
    csv: require('../../../../documents/file-manager-icons/csv.png'),
    html: require('../../../../documents/file-manager-icons/html.png'),
    php: require('../../../../documents/file-manager-icons/php.png'),
    svg: require('../../../../documents/file-manager-icons/svg.png'),
  }

  function getFileIcon(file) {
    if (file.type === 'folder') {
      return icons.folder
    } else {
      if (
        file.name.endsWith('.jpg') ||
        file.name.endsWith('.jpeg') ||
        file.name.endsWith('.png') ||
        file.name.endsWith('.gif') ||
        file.name.endsWith('.bmp') ||
        file.name.endsWith('.tiff') ||
        file.name.endsWith('.svg') ||
        file.name.endsWith('.webp')
      ) {
        return downloadPreviewImage(file.name)
      } else {
        const extension = file.name.split('.').pop()
        if (icons[extension]) {
          return icons[extension]
        } else {
          return icons.file
        }
      }
    }
  }

  return (
    <tr
      className={file.type == 'folder' ? classes.folderRow : classes.fileRow}
      key={index}
      onDoubleClick={() => {
        if (file.type === 'folder') {
          const newPath = [...path, file.name]
          setPath([...newPath])
        }
      }}
    >
      <td className={classes.td}>
        <img className={classes.fileicon} src={getFileIcon(file)}></img>
      </td>
      <td className={classes.td}>
        <div className={classes.userText}>{file.name}</div>
      </td>
      <td className={classes.td}>
        <Button
          aria-controls="simple-menu"
          aria-haspopup="true"
          onClick={openMenu}
          className={classes.actionsButton}
        >
          ...
        </Button>

        <Menu
          id="simple-menu"
          anchorEl={showMenu}
          keepMounted
          open={Boolean(showMenu)}
          onClose={closeMenu}
        >
          {file.type === 'file' ? (
            <MenuItem
              onClick={() => {
                openFileInBrowser(file.name)
                closeMenu()
              }}
            >
              <GetAppIcon />
              {
                {
                  de: 'in browser öffnen',
                  en: 'open in browser',
                  fr: 'in browser öffnen - FR',
                }[selectedLanguage]
              }
            </MenuItem>
          ) : null}

          <MenuItem
            onClick={() => {
              downloadFile(file.name)
              closeMenu()
            }}
          >
            <GetAppIcon />
            {
              {
                de: 'downloaden',
                en: 'download',
                fr: 'downloaden - FR',
              }[selectedLanguage]
            }
          </MenuItem>

          {showRenameOption ? (
            <MenuItem
              onClick={() => {
                let fileExtension = ''
                let fileName = file.name

                if (file.type === 'file') {
                  const dotIndex = file.name.lastIndexOf('.')
                  fileName = file.name.substring(0, dotIndex)
                  fileExtension = file.name.substring(dotIndex)
                }

                let newName = prompt(
                  'Bitte gib den neuen Namen für ' + fileName + ' ein:',
                  fileName
                )

                if (newName !== null && newName !== '') {
                  if (file.type === 'file') {
                    renameFile(file.name, newName + fileExtension)
                  } else {
                    renameFile(file.name, newName)
                  }
                }

                closeMenu()
              }}
            >
              <EditIcon />
              {
                {
                  de: 'umbenennen',
                  en: 'rename',
                  fr: 'umbenennen - FR',
                }[selectedLanguage]
              }
            </MenuItem>
          ) : null}

          {showDeleteOption ? (
            <MenuItem
              className={classes.redMenuItem}
              onClick={async () => {
                let confirmation = await confirm(
                  {
                    de: 'Datei wirklich löschen?',
                    en: 'delete file?',
                    fr: 'Datei wirklich löschen? - FR',
                  }[selectedLanguage]
                )
                if (confirmation === true) {
                  deleteFile(file.name)
                }
                closeMenu()
              }}
            >
              <DeleteIcon />
              {
                {
                  de: 'löschen',
                  en: 'delete',
                  fr: 'löschen - FR',
                }[selectedLanguage]
              }
            </MenuItem>
          ) : null}
        </Menu>
      </td>
      <td className={classes.td}>
        <div className={classes.userText}>{formatBytes(file.size)}</div>
      </td>
      <td className={classes.td}>
        <div className={classes.userText}>{formatDate(file.lastModified)}</div>
      </td>
    </tr>
  )
}
