import React, { useEffect, useState } from 'react'
import {
  Button,
  createStyles,
  makeStyles,
  TextField,
  Theme,
} from '@material-ui/core'
import { User } from '../../../../../users/models/User'
import { Machine } from '../../../../models/Machine'
import { AccessManagementController } from '../../controllers/AccessManagementController'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { SharedDataProvider } from '../../../../../shared/providers/SharedDataProvider'
import { UsersWithAbilityToGetAccess } from './UsersWithAbilityToGetAccess'
import { UsersWithAccess } from './UsersWithAccess'

interface AccessManagementProps {
  machine: Machine
  usersWithAccess
  setUsersWithAccess
  history: any
  selectedLanguage
}

export const AccessManagement = ({
  machine,
  usersWithAccess,
  setUsersWithAccess,
  history,
  selectedLanguage,
}: AccessManagementProps) => {
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      dataContainer: {
        marginTop: 30,
        marginLeft: 20,
        marginRight: 20,
      },
      userDataContainer: {
        display: 'flex',
        alignItems: 'center',
        marginTop: 20,
      },
      userText: {
        color: '#212121',
        fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
        fontSize: 16,
        textAlign: 'left',
        marginRight: 10,
      },
      userButton: {},
      textfield: {
        margin: 0,
        width: 200,
        backgroundColor: 'white',
      },
      button: {
        width: 200,
        borderRadius: '10px',
        backgroundColor: '#1c8033',
        color: 'white',
        variant: 'contained',
        margin: 5,
        fontSize: 15,
        '&:hover': {
          backgroundColor: '#1c8033',
        },
      },
      userProfilePicture: {
        objectFit: 'contain',
        objectPosition: 'center',
        width: '75px',
        borderRadius: '50%',
        marginRight: 10,
      },
      addUserButton: {
        borderRadius: '10px',
        backgroundColor: '#1c8033',
        color: 'white',
        variant: 'contained',
        margin: 5,
        marginLeft: 'auto',
        fontSize: 15,
        '&:hover': {
          backgroundColor: '#1c8033',
        },
      },
      heading: {
        color: '#212121',
        fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
        fontSize: 18,
        textAlign: 'center',
        marginBottom: 10,
      },
      deleteUserButton: {
        borderRadius: '10px',
        backgroundColor: '#f44336',
        color: 'white',
        variant: 'contained',
        margin: 5,
        marginLeft: 'auto',
        fontSize: 15,
        '&:hover': {
          backgroundColor: '#d32215',
        },
      },
      seperator: {
        borderBottom: '1px solid lightgrey',
        marginTop: '10px',
        marginBottom: '10px',
      },
    })
  )
  const classes = useStyles()

  const sharedDataProvider = new SharedDataProvider()

  const accessManagementController: AccessManagementController = new AccessManagementController(
    history
  )

  const [
    usersWithAbilityToGetAccess,
    setUsersWithAbilityToGetAccess,
  ] = useState<User[]>([])

  useEffect(() => {
    let getUsersWithAccessAndUsersWithAbilityToGetAccess = async () => {
      let {
        usersWithAbilityToGetAccess,
        usersWithAccess,
      } = await accessManagementController.getUsersWithAccessAndUsersWithAbilityToGetAccess(
        machine._id
      )
      setUsersWithAbilityToGetAccess(usersWithAbilityToGetAccess)
      setUsersWithAccess(usersWithAccess)
    }
    getUsersWithAccessAndUsersWithAbilityToGetAccess()
  }, [])

  return (
    <div className={classes.dataContainer}>
      <div className={classes.heading}>
        {
          {
            de: 'Zugriffs Management',
            en: 'Access Management',
            fr: 'Zugriffs Management - FR',
          }[selectedLanguage]
        }
      </div>
      <div className={classes.seperator}></div>

      <UsersWithAbilityToGetAccess
        usersWithAbilityToGetAccess={usersWithAbilityToGetAccess}
        setUsersWithAbilityToGetAccess={setUsersWithAbilityToGetAccess}
        usersWithAccess={usersWithAccess}
        setUsersWithAccess={setUsersWithAccess}
        selectedLanguage={selectedLanguage}
      />

      <UsersWithAccess
        usersWithAbilityToGetAccess={usersWithAbilityToGetAccess}
        setUsersWithAbilityToGetAccess={setUsersWithAbilityToGetAccess}
        usersWithAccess={usersWithAccess}
        setUsersWithAccess={setUsersWithAccess}
        selectedLanguage={selectedLanguage}
      />
    </div>
  )
}
