import { Button, createStyles, makeStyles, Theme } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { Footer } from '../../../../shared/components/Footer'
import { Header } from '../../../../shared/components/Header'
import { LoadingAnimation } from '../../../../shared/components/LoadingAnimation'
import { SharedDataProvider } from '../../../../shared/providers/SharedDataProvider'
import { SharedStyleProvider } from '../../../../shared/providers/SharedStyleProvider'
import { emptyUser, User } from '../../../models/User'
import { HomeController } from '../controllers/HomeController'

export const Home = (props) => {
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      homeScreen: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '86vh',
        width: '100%',
        backgroundColor: '#0b2242',
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
      },
      userProfilePicture: {
        objectFit: 'contain',
        objectPosition: 'center',
        width: '240px',
        borderRadius: '50%',
        marginBottom: 30,
      },
      companyLogo: {
        maxWidth: 200,
        maxHeight: 100,
        marginBottom: 30,
      },
      homeOrganizationText: {
        color: '#000000',
        fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
        fontSize: 14,
        textAlign: 'center',
        margin: 7,
      },
      homeAdminText: {
        color: '#a6a5a5',
        fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
        fontSize: 14,
        textAlign: 'left',
      },
      homeContactText: {
        color: '#000000',
        fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
        fontSize: 14,
        textAlign: 'left',
      },
      homeBox: {
        borderRadius: '5px',
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'wrap',
        justifyContent: 'flex-start',
        backgroundColor: 'white',
        padding: 25,
        width: 250,
      },
      appContent: {
        minHeight: '86vh',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        flexWrap: 'wrap',
        alignItems: 'center',
      },
      dataContainer: {
        marginTop: 30,
        marginLeft: 20,
        marginRight: 20,
      },
      button: {
        width: 200,
        borderRadius: '10px',
        backgroundColor: '#1c8033',
        color: 'white',
        variant: 'contained',
        margin: 5,
        fontSize: 15,
        '&:hover': {
          backgroundColor: '#1c8033',
        },
      },
    })
  )
  const classes = useStyles()

  const sharedDataProvider = new SharedDataProvider()

  const homeController: HomeController = new HomeController(props.history)

  let [user, setUser] = useState<User>({ ...emptyUser })

  useEffect(() => {
    const { params } = props.match
    let getUser = async () => {
      let user: User = await homeController.getUserById(
        window.localStorage.getItem('loggedInUserId')
      )
      setUser({ ...user })
    }
    getUser()
  }, [])

  return (
    <div>
      <Header
        page={'home'}
        history={props.history}
        selectedLanguage={props.selectedLanguage}
        setSelectedLanguage={props.setSelectedLanguage}
      />

      <div className={classes.appContent}>
        <div
          className={classes.homeScreen}
          style={{
            backgroundImage: `url(${
              sharedDataProvider.host +
              '/companies/getCompanyBackground?id=' +
              window.localStorage.getItem('loggedInUserCompany')
            })`,
          }}
        >
          <img
            className={classes.userProfilePicture}
            src={
              sharedDataProvider.host +
              '/users/getUserProfilePicture?id=' +
              window.localStorage.getItem('loggedInUserId') +
              '&' +
              new Date()
            }
          ></img>
          <img
            className={classes.companyLogo}
            src={
              sharedDataProvider.host +
              '/companies/getCompanyLogo?id=' +
              window.localStorage.getItem('loggedInUserCompany') +
              '&' +
              new Date()
            }
          ></img>

          <div className={classes.homeBox}>
            <div className={classes.homeAdminText}>
              {sharedDataProvider.getRoleName(
                window.localStorage.getItem('loggedInUserRole')
              )}
            </div>
            <div className={classes.homeContactText}>
              {user.firstName + ' ' + user.lastName}
            </div>
            <div className={classes.homeContactText}>
              {'Zuständigkeitsbereich: ' + user.areaOfResponsibility}
            </div>
            <div className={classes.homeContactText}>
              {'Mail: ' + user.mail}
            </div>
            <div className={classes.homeContactText}>
              {'Tel: ' + user.phone}
            </div>
            <div className={classes.homeContactText}>
              {'Berechtigungs-Level: ' + user.unlockLevel}
            </div>
          </div>
          <Button
            className={classes.button}
            onClick={() => props.history.push(`/user/${user._id}`)}
          >
            {
              {
                de: 'Profil bearbeiten',
                en: 'Edit Profile',
                fr: 'Profil bearbeiten - FR',
              }[props.selectedLanguage]
            }
          </Button>
          <Button
            className={classes.button}
            onClick={() => props.history.push('/changePassword')}
          >
            {
              {
                de: 'Passwort ändern',
                en: 'Change Password',
                fr: 'Passwort ändern - FR',
              }[props.selectedLanguage]
            }
          </Button>
          {window.localStorage.getItem('loggedInUserRole') === 'admin' ||
          window.localStorage.getItem('loggedInUserRole') ===
            'manufacturerAdmin' ||
          window.localStorage.getItem('loggedInUserRole') ===
            'manufacturerAdminLight' ||
          window.localStorage.getItem('loggedInUserRole') === 'technician' ? (
            <Button
              className={classes.button}
              onClick={() => props.history.push('/codeGeneration')}
            >
              {
                {
                  de: 'Code generieren',
                  en: 'Generate Code',
                  fr: 'Code generieren - FR',
                }[props.selectedLanguage]
              }
            </Button>
          ) : null}
          {window.localStorage.getItem('loggedInUserRole') === 'admin' ? (
            <Button
              className={classes.button}
              onClick={() => props.history.push('/companyCodes')}
            >
              {
                {
                  de: 'Company Codes verwalten',
                  en: 'Manage Company Codes',
                  fr: 'Company Codes verwalten - FR',
                }[props.selectedLanguage]
              }
            </Button>
          ) : null}
        </div>
      </div>

      <Footer
        history={props.history}
        selectedLanguage={props.selectedLanguage}
      />
    </div>
  )
}
