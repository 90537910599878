import { SharedDataProvider } from '../../../../shared/providers/SharedDataProvider'
import 'regenerator-runtime'
import { User } from '../../../models/User'
import { ToastController } from '../../../../shared/controllers/ToastController'
import { AuthenticationController } from '../../../../shared/controllers/AuthenticationController'
import { RequestController } from '../../../../shared/controllers/RequestController'

export class CreateNewUserController {
  sharedDataProvider: SharedDataProvider
  toastController: ToastController
  requestController: RequestController
  history: any

  constructor(history) {
    this.sharedDataProvider = new SharedDataProvider()
    this.toastController = new ToastController()
    this.requestController = new RequestController(history)
    this.history = history
  }

  public async createNewUser(user: User, userProfilePicture, history) {
    user.dateOfEntry = new Date()
    let userObject = {
      user: user,
      userProfilePicture: userProfilePicture,
    }
    let path = this.sharedDataProvider.host + '/users/createNewUser'
    let data = await this.requestController.postRequest(path, userObject)

    // show toast
    this.toastController.showToast_createNewUser(user)

    this.history.push(`/user/${data}`)
    return data
  }
}
