import { SharedDataProvider } from '../../../../shared/providers/SharedDataProvider'
import 'regenerator-runtime'
import { User } from '../../../models/User'
import { AuthenticationController } from '../../../../shared/controllers/AuthenticationController'
import { RequestController } from '../../../../shared/controllers/RequestController'

export class UserSearchController {
  sharedDataProvider: SharedDataProvider
  requestController: RequestController

  constructor(history) {
    this.sharedDataProvider = new SharedDataProvider()
    this.requestController = new RequestController(history)
  }

  public async searchForUsers(
    searchValue,
    searchProperty,
    searchIndexFrom,
    searchIndexTo
  ) {
    let path =
      this.sharedDataProvider.host +
      '/users/' +
      this.getRoute(searchProperty) +
      '?' +
      searchProperty +
      '=' +
      searchValue +
      '&searchIndexFrom=' +
      searchIndexFrom +
      '&searchIndexTo=' +
      searchIndexTo
    let data = await this.requestController.getRequest(path)
    return data
  }

  private getRoute(searchProperty) {
    if (searchProperty === 'name') {
      return 'getUsersByName'
    } else if (searchProperty === 'company') {
      return 'getUsersByCompany'
    }
  }
}
