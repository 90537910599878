import { makeStyles, Theme, createStyles } from '@material-ui/core'
import React from 'react'
import { Link } from 'react-router-dom'
import { User } from '../../../models/User'
import { SharedDataProvider } from '../../../../shared/providers/SharedDataProvider'

interface UserSearchResultProps {
  user: User
}

export const UserSearchResult = ({ user }: UserSearchResultProps) => {
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      searchResult: {
        backgroundColor: 'ffffff',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        borderRadius: '5px',
        width: 240,
        margin: 5,
        overflow: 'hidden',
        boxShadow:
          '0 5px 5px 0 rgba(0, 0, 0, 0.25), 0 5px 5px 0 rgba(0, 0, 0, 0.025)',
      },
      searchResultTop: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      },
      userProfilePicture: {
        objectFit: 'contain',
        objectPosition: 'center',
        width: '200px',
        borderRadius: '50%',
      },
      companyLogo: {
        objectFit: 'contain',
        objectPosition: 'center',
        height: '80px',
        width: '180px',
      },
      searchResultBottom: {
        display: 'flex',
        margin: 5,
        flexDirection: 'column',
        position: 'relative',
      },
      searchResultMainData: {
        color: '#212121',
        fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
        fontSize: 16,
        textAlign: 'left',
      },
      searchResultOtherData: {
        color: '#212121',
        fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
        fontSize: 12,
        textAlign: 'left',
      },
    })
  )
  const classes = useStyles()

  const sharedDataProvider = new SharedDataProvider()

  return (
    <Link
      key={user._id}
      to={`user/${user._id}`}
      className={classes.searchResult}
      style={{ textDecoration: 'none' }}
    >
      <div className={classes.searchResultTop}>
        <img
          className={classes.userProfilePicture}
          src={
            sharedDataProvider.host +
            '/users/getUserProfilePicture?id=' +
            user._id +
            '&' +
            new Date()
          }
        ></img>
      </div>
      {window.localStorage.getItem('loggedInUserRole') === 'admin' ? (
        <div className={classes.searchResultTop}>
          <img
            className={classes.companyLogo}
            src={
              sharedDataProvider.host +
              '/companies/getCompanyLogo?id=' +
              user.company +
              '&' +
              new Date()
            }
          ></img>
        </div>
      ) : null}
      <div className={classes.searchResultBottom}>
        <div className={classes.searchResultMainData}>
          {user.firstName + ' ' + user.lastName}
        </div>

        <div className={classes.searchResultOtherData}>
          <div>{'Mail: ' + user.mail}</div>
          <div>{'Rolle: ' + sharedDataProvider.getRoleName(user.role)}</div>
        </div>
      </div>
    </Link>
  )
}
