import { Button, createStyles, makeStyles, Theme } from '@material-ui/core'
import { PhotoCamera } from '@material-ui/icons'
import React, { useEffect, useState } from 'react'
import { Footer } from '../../../../shared/components/Footer'
import { Header } from '../../../../shared/components/Header'
import { LoadingAnimation } from '../../../../shared/components/LoadingAnimation'
import { SharedDataProvider } from '../../../../shared/providers/SharedDataProvider'
import { UserPageController } from '../controllers/UserPageController'
import { ImageController } from '../../../../shared/controllers/ImageController'
import { User, emptyUser } from '../../../models/User'
import { UserMainData } from '../../../shared/components/UserMainData'
import { CompanyAssignmentController } from '../../../shared/controllers/CompanyAssignmentController'
import { UserProfilePicture } from '../../../shared/components/UserProfilePicture'
import { UtilController } from '../../../../shared/controllers/UtilController'

export const UserPage = (props) => {
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      appContent: {
        minHeight: '86vh',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        flexWrap: 'wrap',
        alignItems: 'center',
      },
      dataContainer: {
        marginTop: 30,
        marginLeft: 20,
        marginRight: 20,
      },
      button: {
        width: '100%',
        borderRadius: '10px',
        backgroundColor: '#1c8033',
        color: 'white',
        variant: 'contained',
        margin: 5,
        fontSize: 15,
        '&:hover': {
          backgroundColor: '#1c8033',
        },
      },
      deleteButton: {
        width: '100%',
        borderRadius: '10px',
        backgroundColor: '#f44336',
        color: 'white',
        variant: 'contained',
        margin: 5,
        fontSize: 15,
        '&:hover': {
          backgroundColor: '#d32215',
        },
      },
      userProfilePicture: {
        objectFit: 'contain',
        objectPosition: 'center',
        width: '240px',
        borderRadius: '50%',
      },
      hiddenInput: {
        display: 'none',
      },
    })
  )
  const classes = useStyles()

  const sharedDataProvider = new SharedDataProvider()

  const userPageController: UserPageController = new UserPageController(
    props.history
  )
  const imageController: ImageController = new ImageController()
  const utilController = new UtilController()

  const [isLoading, setIsLoading] = useState(true)
  let [user, setUser] = useState<User>({ ...emptyUser })
  let [userProfilePicture, setUserProfilePicture] = useState<
    string | ArrayBuffer
  >()

  const change = (key: string, value: string) => {
    user[key] = value
    setUser({ ...user })
  }

  async function imageUpload() {
    // get image
    let files = document.getElementById('imageUpload').files

    // transform image into base64
    // prepare image by resizing etc
    let file = await imageController.prepareImageForUploadSquared(files[0])
    let reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => {
      let base64 = reader.result
      setUserProfilePicture(base64)
    }
  }

  useEffect(() => {
    setIsLoading(true)
    // fetch user and more data from backend
    const { params } = props.match
    let getData = async () => {
      // user
      let user: User = await userPageController.getUserById(params.userId)
      setUser({ ...user })

      setIsLoading(false)
    }
    getData()
  }, [])

  const unlockLevelMismatch = () => {
    let unlockLevelString = window.localStorage.getItem(
      'loggedInUserUnlockLevel'
    )
    // If the localStorage item does not exist or is not a number, default to 0
    let unlockLevel = unlockLevelString ? parseInt(unlockLevelString) : 0
    // If the parsing fails (returns NaN), also default to 0
    if (isNaN(unlockLevel)) {
      unlockLevel = 0
    }

    return unlockLevel < parseInt(user.unlockLevel)
  }

  return (
    <div>
      <Header
        page={'user'}
        history={props.history}
        selectedLanguage={props.selectedLanguage}
        setSelectedLanguage={props.setSelectedLanguage}
      />

      {isLoading ? (
        <div className={classes.appContent}>
          <div className={classes.dataContainer}>
            <LoadingAnimation />
          </div>
        </div>
      ) : (
        <div className={classes.appContent}>
          <UserProfilePicture
            user={user}
            userProfilePicture={userProfilePicture}
            setUserProfilePicture={setUserProfilePicture}
            selectedLanguage={props.selectedLanguage}
          />

          <UserMainData
            user={user}
            change={change}
            history={props.history}
            selectedLanguage={props.selectedLanguage}
          />

          {!(
            window.localStorage.getItem('loggedInUserRole') ===
              'manufacturerAdminLight' && user.role === 'manufacturerAdmin'
          ) &&
          !(
            window.localStorage.getItem('loggedInUserRole') ===
              'manufacturerAdminLight' && user.role === 'admin'
          ) ? (
            <div className={classes.dataContainer}>
              <Button
                disabled={
                  user.firstName === '' ||
                  user.lastName === '' ||
                  !utilController.validateEmail(user.mail) ||
                  unlockLevelMismatch()
                }
                className={classes.button}
                onClick={() => {
                  userPageController.saveUser(
                    user,
                    userProfilePicture,
                    props.history
                  )
                }}
              >
                {
                  {
                    de: 'speichern',
                    en: 'save',
                    fr: 'speichern - FR',
                  }[props.selectedLanguage]
                }
              </Button>
            </div>
          ) : null}

          {window.localStorage.getItem('loggedInUserRole') === 'admin' &&
          (user.role === 'manufacturerAdmin' ||
            user.role === 'manufacturerAdminLight') ? (
            <div className={classes.dataContainer}>
              <Button
                className={classes.button}
                onClick={async () => {
                  userPageController.makeUserTheContactPerson(
                    user,
                    props.history
                  )
                }}
              >
                {
                  {
                    de: 'Nutzer zum Ansprechpartner des Unternehmens machen',
                    en: 'Make this user the contact person for the company',
                    fr:
                      'Nutzer zum Ansprechpartner des Unternehmens machen - FR',
                  }[props.selectedLanguage]
                }
              </Button>
            </div>
          ) : null}

          {window.localStorage.getItem('loggedInUserId') !== user._id &&
          !(
            window.localStorage.getItem('loggedInUserRole') ===
              'manufacturerAdminLight' && user.role === 'manufacturerAdmin'
          ) &&
          !(
            window.localStorage.getItem('loggedInUserRole') ===
              'manufacturerAdminLight' && user.role === 'admin'
          ) ? (
            <div className={classes.dataContainer}>
              <Button
                className={classes.deleteButton}
                onClick={async () => {
                  let confirmation = await confirm('User wirklich löschen?')
                  if (confirmation === true) {
                    userPageController.deleteUser(user, props.history)
                  }
                }}
              >
                {
                  {
                    de: 'löschen',
                    en: 'delete',
                    fr: 'löschen - FR',
                  }[props.selectedLanguage]
                }
              </Button>
            </div>
          ) : null}
        </div>
      )}
      <Footer
        history={props.history}
        selectedLanguage={props.selectedLanguage}
      />
    </div>
  )
}
