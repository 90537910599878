import React, { useEffect, useState } from 'react'
import { browserHistory } from 'react-router'
import { ToastContainer } from 'react-toastify'
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles'
import { CreateNewCompany } from '../../companies/pages/createNewCompany/components'
import { CompanySearch } from '../../companies/pages/companySearch/components'
import { CompanyPage } from '../../companies/pages/companyPage/components'
import { CreateNewUser } from '../../users/pages/createNewUser/components'
import { UserPage } from '../../users/pages/userPage/components'
import { UserSearch } from '../../users/pages/userSearch/components'
import { CreateNewMachine } from '../../machines/pages/createNewMachine/components'
import { MachinePage } from '../../machines/pages/machinePage/components'
import { BrowserRouter as Router, Route, Redirect } from 'react-router-dom'
import { Login } from '../../users/pages/login/components'
import { ProtectedRoute } from './ProtectedRoute'
import { Home } from '../../users/pages/home/components'
import { Imprint } from '../../imprint/components'
import { Contact } from '../../contact/components'
import { Privacy } from '../../privacy/components'
import 'react-toastify/dist/ReactToastify.css'
import './style.scss'
import { MachineSearchCompanySelection } from '../../machines/pages/machineSearch/components/companySelection'
import { Machines } from '../../machines/pages/machineSearch/components/machines'
import { MachineSearchMachineTypeSelection } from '../../machines/pages/machineSearch/components/machineTypeSelection'
import { CreateNewMachineType } from '../../machineTypes/pages/createNewMachineType/components'
import { MachineTypePage } from '../../machineTypes/pages/machineTypePage/components'
import { MachineTypeSearchCompanySelection } from '../../machineTypes/pages/machineTypeSearch/components/companySelection'
import { MachineTypes } from '../../machineTypes/pages/machineTypeSearch/components/machineTypes'
import { ChangePassword } from '../../users/pages/changePassword/components'
import { CodeGeneration } from '../../users/pages/codeGeneration/components'
import { CompanyCodes } from '../../users/pages/companyCodes/components'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      textAlign: 'center',
    },
  })
)

export const App = () => {
  const classes = useStyles()
  const [selectedLanguage, setSelectedLanguage] = useState('de')

  return (
    <Router history={browserHistory}>
      <div className={classes.root}>
        <Route
          exact
          path="/login"
          render={(props) => (
            <Login
              {...props}
              selectedLanguage={selectedLanguage}
              setSelectedLanguage={setSelectedLanguage}
            />
          )}
        />

        <ProtectedRoute
          exact
          path="/home"
          selectedLanguage={selectedLanguage}
          setSelectedLanguage={setSelectedLanguage}
          component={Home}
        />

        <ProtectedRoute
          exact
          path="/changePassword"
          selectedLanguage={selectedLanguage}
          setSelectedLanguage={setSelectedLanguage}
          component={ChangePassword}
        />

        <ProtectedRoute
          exact
          path="/createNewCompany"
          selectedLanguage={selectedLanguage}
          setSelectedLanguage={setSelectedLanguage}
          component={CreateNewCompany}
        />

        <ProtectedRoute
          exact
          path="/companies"
          selectedLanguage={selectedLanguage}
          setSelectedLanguage={setSelectedLanguage}
          component={CompanySearch}
        />

        <ProtectedRoute
          exact
          path="/company/:companyId"
          selectedLanguage={selectedLanguage}
          setSelectedLanguage={setSelectedLanguage}
          component={CompanyPage}
        />

        <ProtectedRoute
          exact
          path="/createNewUser"
          selectedLanguage={selectedLanguage}
          setSelectedLanguage={setSelectedLanguage}
          component={CreateNewUser}
        />

        <ProtectedRoute
          exact
          path="/users"
          selectedLanguage={selectedLanguage}
          setSelectedLanguage={setSelectedLanguage}
          component={UserSearch}
        />

        <ProtectedRoute
          exact
          path="/user/:userId"
          selectedLanguage={selectedLanguage}
          setSelectedLanguage={setSelectedLanguage}
          component={UserPage}
        />

        <ProtectedRoute
          exact
          path="/createNewMachine/:companyId/:machineTypeId"
          selectedLanguage={selectedLanguage}
          setSelectedLanguage={setSelectedLanguage}
          component={CreateNewMachine}
        />

        <ProtectedRoute
          exact
          path="/machines"
          selectedLanguage={selectedLanguage}
          setSelectedLanguage={setSelectedLanguage}
          component={MachineSearchCompanySelection}
        />

        <ProtectedRoute
          exact
          path="/machines/:companyId"
          selectedLanguage={selectedLanguage}
          setSelectedLanguage={setSelectedLanguage}
          component={MachineSearchMachineTypeSelection}
        />

        <ProtectedRoute
          exact
          path="/machines/:companyId/:machineTypeId"
          selectedLanguage={selectedLanguage}
          setSelectedLanguage={setSelectedLanguage}
          component={Machines}
        />

        <ProtectedRoute
          exact
          path="/machine/:machineId"
          selectedLanguage={selectedLanguage}
          setSelectedLanguage={setSelectedLanguage}
          component={MachinePage}
        />

        <ProtectedRoute
          exact
          path="/createNewMachineType/:companyId"
          selectedLanguage={selectedLanguage}
          setSelectedLanguage={setSelectedLanguage}
          component={CreateNewMachineType}
        />

        <ProtectedRoute
          exact
          path="/machineTypes"
          selectedLanguage={selectedLanguage}
          setSelectedLanguage={setSelectedLanguage}
          component={MachineTypeSearchCompanySelection}
        />

        <ProtectedRoute
          exact
          path="/machineTypes/:companyId"
          selectedLanguage={selectedLanguage}
          setSelectedLanguage={setSelectedLanguage}
          component={MachineTypes}
        />

        <ProtectedRoute
          exact
          path="/machineType/:machineTypeId"
          selectedLanguage={selectedLanguage}
          setSelectedLanguage={setSelectedLanguage}
          component={MachineTypePage}
        />

        <ProtectedRoute
          exact
          path="/codeGeneration"
          selectedLanguage={selectedLanguage}
          setSelectedLanguage={setSelectedLanguage}
          component={CodeGeneration}
        />

        <ProtectedRoute
          exact
          path="/companyCodes"
          selectedLanguage={selectedLanguage}
          setSelectedLanguage={setSelectedLanguage}
          component={CompanyCodes}
        />

        <Route
          exact
          path="/imprint"
          render={(props) => (
            <Imprint
              {...props}
              selectedLanguage={selectedLanguage}
              setSelectedLanguage={setSelectedLanguage}
            />
          )}
        />

        <Route
          exact
          path="/contact"
          render={(props) => (
            <Contact
              {...props}
              selectedLanguage={selectedLanguage}
              setSelectedLanguage={setSelectedLanguage}
            />
          )}
        />

        <Route
          exact
          path="/privacy"
          render={(props) => (
            <Privacy
              {...props}
              selectedLanguage={selectedLanguage}
              setSelectedLanguage={setSelectedLanguage}
            />
          )}
        />

        <Route
          exact
          path="/"
          render={() => {
            return <Redirect to="/login" />
          }}
        />
      </div>

      <ToastContainer />
    </Router>
  )
}
