import { SharedDataProvider } from '../../../../shared/providers/SharedDataProvider'
import 'regenerator-runtime'
import { User } from '../../../../users/models/User'
import { AuthenticationController } from '../../../../shared/controllers/AuthenticationController'
import { RequestController } from '../../../../shared/controllers/RequestController'

interface IUsersWithAccessAndUsersWithAbilityToGetAccess {
  usersWithAccess: User[]
  usersWithAbilityToGetAccess: User[]
}

export class AccessManagementController {
  sharedDataProvider: SharedDataProvider
  requestController: RequestController

  constructor(history) {
    this.sharedDataProvider = new SharedDataProvider()
    this.requestController = new RequestController(history)
  }

  public async getUsersWithAccessAndUsersWithAbilityToGetAccess(
    machineId
  ): Promise<IUsersWithAccessAndUsersWithAbilityToGetAccess> {
    let path =
      this.sharedDataProvider.host +
      '/machines/getUsersWithAccessAndUsersWithAbilityToGetAccess' +
      '?id=' +
      machineId
    let data = await this.requestController.getRequest(path)
    return data
  }
}
