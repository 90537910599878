import { SharedDataProvider } from '../providers/SharedDataProvider'
import 'regenerator-runtime'

interface IAuthenticationData {
  newTokensGenerated: Boolean
  newAccessToken?: string
  newRefreshToken?: string
}

export class AuthenticationController {
  sharedDataProvider: SharedDataProvider

  constructor() {
    this.sharedDataProvider = new SharedDataProvider()
  }

  public updateAuthenticationData(data: IAuthenticationData) {
    if (data.newTokensGenerated === true) {
      window.localStorage.setItem('accesstoken', data.newAccessToken)
      window.localStorage.setItem('refreshtoken', data.newRefreshToken)
    }
  }
}
