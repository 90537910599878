import {
  Button,
  createStyles,
  FormControl,
  InputLabel,
  makeStyles,
  Select,
  TextField,
  Theme,
} from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import React, { useEffect, useState } from 'react'
import { Footer } from '../../../../shared/components/Footer'
import { Header } from '../../../../shared/components/Header'
import { LoadingAnimation } from '../../../../shared/components/LoadingAnimation'
import { SharedDataProvider } from '../../../../shared/providers/SharedDataProvider'
import { CodeGenerationController } from '../controllers/CodeGenerationController'

export const CodeGeneration = (props) => {
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      textfield: {
        margin: 0,
        backgroundColor: 'white',
        width: 200,
      },
      appContent: {
        minHeight: '86vh',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        flexWrap: 'wrap',
        alignItems: 'center',
      },
      dataContainer: {
        marginTop: 30,
        marginLeft: 20,
        marginRight: 20,
      },
      button: {
        width: 200,
        borderRadius: '10px',
        backgroundColor: '#1c8033',
        color: 'white',
        variant: 'contained',
        margin: 5,
        fontSize: 15,
        '&:hover': {
          backgroundColor: '#1c8033',
        },
      },
    })
  )
  const classes = useStyles()

  const sharedDataProvider = new SharedDataProvider()

  const codeGenerationController: CodeGenerationController = new CodeGenerationController(
    props.history
  )

  const [isLoading, setIsLoading] = useState(true)

  let [randomCode, setRandomCode] = useState('')
  let [option, setOption] = useState('1')
  let [notes, setNotes] = useState('')
  let [generatedCode, setGeneratedCode] = useState('')

  let [accessibleCompaniesAndIds, setAccessibleCompaniesAndIds] = useState([])
  let [companyId, setCompanyId] = useState('')
  let [codeOptions, setCodeOptions] = useState([])
  let [machineTypeOptions, setMachineTypeOptions] = useState([])
  let [machineNumberOptions, setMachineNumberOptions] = useState([])

  const [machineTypeNumberMap, setMachineTypeNumberMap] = useState({})

  let [machineType, setMachineType] = useState('')
  let [machineNumber, setMachineNumber] = useState('')
  const [tmpMachineType, setTmpMachineType] = useState('')
  const [tmpMachineNumber, setTmpMachineNumber] = useState('')

  const updateMachineTypeOptions = (enteredMachineTypeText) => {
    let machineTypes = Object.keys(machineTypeNumberMap)
    let types = machineTypes.filter((mType) =>
      mType.toLowerCase().includes(enteredMachineTypeText.toLowerCase())
    )
    setMachineTypeOptions(types)
  }

  const updateMachineNumberOptions = (mType, enteredMachineNumberText) => {
    let mNumberOptions = []
    let mNumbers = machineTypeNumberMap[mType]
    if (mNumbers) {
      mNumberOptions = mNumbers.filter((mNumber) =>
        mNumber.toLowerCase().includes(enteredMachineNumberText.toLowerCase())
      )
    }
    setMachineNumberOptions(mNumberOptions)
  }

  useEffect(() => {
    setIsLoading(true)
    // fetch user and more data from backend
    let getData = async () => {
      // companies and ids
      let accessibleCompaniesAndIds = await codeGenerationController.getAccessibleCompaniesAndIds()
      setAccessibleCompaniesAndIds(accessibleCompaniesAndIds)

      let loggedInUserCompany = window.localStorage.getItem(
        'loggedInUserCompany'
      )
      setCompanyId(loggedInUserCompany)

      setIsLoading(false)
    }
    getData()
  }, [])

  useEffect(() => {
    setIsLoading(true)
    // fetch user and more data from backend
    let getData = async () => {
      let cId =
        companyId !== ''
          ? companyId
          : window.localStorage.getItem('loggedInUserCompany')
      let codeOptions = await codeGenerationController.getCodeOptionsByCompanyId(
        cId
      )
      setCodeOptions(codeOptions)

      let machineTypeNumberMap = await codeGenerationController.getMachineTypeNumberMapByCompanyId(
        cId
      )
      setMachineTypeNumberMap(machineTypeNumberMap)

      setIsLoading(false)
    }
    getData()
  }, [companyId])

  useEffect(() => {
    updateMachineTypeOptions('')
    updateMachineNumberOptions('', '')
  }, [machineTypeNumberMap])

  return (
    <div>
      <Header
        page={'codeGeneration'}
        history={props.history}
        selectedLanguage={props.selectedLanguage}
        setSelectedLanguage={props.setSelectedLanguage}
      />

      {isLoading ? (
        <div className={classes.appContent}>
          <div className={classes.dataContainer}>
            <LoadingAnimation />
          </div>
        </div>
      ) : (
        <div className={classes.appContent}>
          {window.localStorage.getItem('loggedInUserRole') === 'admin' ? (
            <div className={classes.dataContainer}>
              <FormControl variant="filled" size="small">
                <InputLabel htmlFor="outlined-age-native-simple">
                  {
                    {
                      de: 'Unternehmen',
                      en: 'Company',
                      fr: 'Unternehmen - FR',
                    }[props.selectedLanguage]
                  }
                </InputLabel>
                <Select
                  className={classes.textfield}
                  native
                  label="Company"
                  value={companyId}
                  onChange={(e) => setCompanyId(e.target.value as string)}
                  inputProps={{
                    name: 'company',
                    id: 'company',
                  }}
                >
                  {accessibleCompaniesAndIds.map((company) => (
                    <option key={company.id} value={company.id}>
                      {company.name}
                    </option>
                  ))}
                </Select>
              </FormControl>
            </div>
          ) : null}

          <div className={classes.dataContainer}>
            <TextField
              className={classes.textfield}
              size="small"
              id="randomCode"
              label="Random Code"
              variant="filled"
              defaultValue={randomCode}
              onBlur={(e) => setRandomCode(e.target.value as string)}
            />
          </div>

          <div className={classes.dataContainer}>
            <FormControl variant="filled" size="small">
              <InputLabel htmlFor="outlined-age-native-simple">
                {
                  {
                    de: 'Option',
                    en: 'Option',
                    fr: 'Option - FR',
                  }[props.selectedLanguage]
                }
              </InputLabel>
              <Select
                className={classes.textfield}
                native
                label="Option"
                value={option}
                onChange={(e) => setOption(e.target.value as string)}
                inputProps={{
                  name: 'option',
                  id: 'option',
                }}
              >
                {codeOptions.map((option) => (
                  <option key={option.id} value={option.id}>
                    {option.effect}
                  </option>
                ))}
              </Select>
            </FormControl>
          </div>

          <div className={classes.dataContainer}>
            <Autocomplete
              id="machineType"
              value={tmpMachineType}
              onInputChange={(event: any, newValue: string | null) => {
                setTmpMachineType(newValue)
                updateMachineTypeOptions(newValue)
              }}
              onBlur={(e) => {
                setMachineType(tmpMachineType)
                updateMachineNumberOptions(tmpMachineType, '')
              }}
              freeSolo
              options={machineTypeOptions}
              renderInput={(params) => (
                <TextField
                  {...params}
                  className={classes.textfield}
                  size="small"
                  label="Machine Type"
                  margin="normal"
                  variant="filled"
                />
              )}
            />
          </div>
          <div className={classes.dataContainer}>
            <Autocomplete
              id="machineNumber"
              value={tmpMachineNumber}
              onInputChange={(event: any, newValue: string | null) => {
                setTmpMachineNumber(newValue)
                updateMachineNumberOptions(machineType, newValue)
              }}
              onBlur={(e) => {
                setMachineNumber(tmpMachineNumber)
              }}
              freeSolo
              options={machineNumberOptions}
              renderInput={(params) => (
                <TextField
                  {...params}
                  className={classes.textfield}
                  size="small"
                  label="Machine Number"
                  margin="normal"
                  variant="filled"
                />
              )}
            />
          </div>

          <div className={classes.dataContainer}>
            <TextField
              className={classes.textfield}
              size="small"
              id="notes"
              label="Bemerkungen"
              variant="filled"
              defaultValue={notes}
              onBlur={(e) => setNotes(e.target.value as string)}
            />
          </div>
          <div className={classes.dataContainer}>
            <Button
              disabled={randomCode.length !== 4}
              className={classes.button}
              onClick={async () => {
                setGeneratedCode('')
                let newGeneratedCode = await codeGenerationController.generateCode(
                  randomCode,
                  option,
                  machineType,
                  machineNumber,
                  notes,
                  companyId
                )
                setGeneratedCode(newGeneratedCode)
              }}
            >
              {
                {
                  de: 'Code generieren',
                  en: 'Generate Code',
                  fr: 'Code generieren - FR',
                }[props.selectedLanguage]
              }
            </Button>
          </div>
          <div className={classes.dataContainer}>
            {generatedCode !== '' ? <h1>{'Code: ' + generatedCode}</h1> : null}
          </div>
        </div>
      )}

      <Footer
        history={props.history}
        selectedLanguage={props.selectedLanguage}
      />
    </div>
  )
}
