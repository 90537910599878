import { Button, createStyles, makeStyles, Theme } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { CreateNewCompanyController } from '../controllers/CreateNewCompanyController'
import { Company, emptyCompany } from '../../../models/Company'
import { PhotoCamera } from '@material-ui/icons'
import { Footer } from '../../../../shared/components/Footer'
import { Header } from '../../../../shared/components/Header'
import { ImageController } from '../../../../shared/controllers/ImageController'
import { CompanyMainData } from '../../../shared/components/CompanyMainData'
import { CompanyLogo } from '../../../shared/components/CompanyLogo'
import { CompanyBackground } from '../../../shared/components/CompanyBackground'

export const CreateNewCompany = (props) => {
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      appContent: {
        minHeight: '86vh',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        flexWrap: 'wrap',
        alignItems: 'center',
      },
      dataContainer: {
        marginTop: 30,
        marginLeft: 20,
        marginRight: 20,
      },
      button: {
        width: '100%',
        borderRadius: '10px',
        backgroundColor: '#1c8033',
        color: 'white',
        variant: 'contained',
        margin: 5,
        fontSize: 15,
        '&:hover': {
          backgroundColor: '#1c8033',
        },
      },
      companyLogo: {
        objectFit: 'contain',
        objectPosition: 'center',
        width: '360px',
      },
      companyBackground: {
        objectFit: 'contain',
        objectPosition: 'center',
        width: '360px',
      },
      hiddenInput: {
        display: 'none',
      },
    })
  )
  const classes = useStyles()

  const createNewCompanyController: CreateNewCompanyController = new CreateNewCompanyController(
    props.history
  )

  let [company, setCompany] = useState<Company>({ ...emptyCompany })
  let [companyLogo, setCompanyLogo] = useState<string | ArrayBuffer>()
  let [companyBackground, setCompanyBackground] = useState<
    string | ArrayBuffer
  >()

  const change = (key: string, value: string) => {
    company[key] = value
    setCompany({ ...company })
  }

  return (
    <div>
      <Header
        page={'createNewCompany'}
        history={props.history}
        selectedLanguage={props.selectedLanguage}
        setSelectedLanguage={props.setSelectedLanguage}
      />

      <div className={classes.appContent}>
        <CompanyLogo
          company={company}
          companyLogo={companyLogo}
          setCompanyLogo={setCompanyLogo}
        />

        <CompanyBackground
          company={company}
          companyBackground={companyBackground}
          setCompanyBackground={setCompanyBackground}
        />

        <CompanyMainData company={company} change={change} />

        <div className={classes.dataContainer}>
          <Button
            className={classes.button}
            onClick={() => {
              createNewCompanyController.createNewCompany(
                company,
                companyLogo,
                companyBackground,
                props.history
              )
            }}
          >
            {
              {
                de: 'Unternehmen erstellen',
                en: 'create company',
                fr: 'Unternehmen erstellen - FR',
              }[props.selectedLanguage]
            }
          </Button>
        </div>
      </div>

      <Footer
        history={props.history}
        selectedLanguage={props.selectedLanguage}
      />
    </div>
  )
}
