import { SharedDataProvider } from '../../../../shared/providers/SharedDataProvider'
import 'regenerator-runtime'
import { Machine } from '../../../models/Machine'
import { ToastController } from '../../../../shared/controllers/ToastController'
import { Company } from '../../../models/Company'
import { MachineType } from '../../../models/MachineType'
import { AuthenticationController } from '../../../../shared/controllers/AuthenticationController'
import { RequestController } from '../../../../shared/controllers/RequestController'

export class CreateNewMachineController {
  sharedDataProvider: SharedDataProvider
  toastController: ToastController
  requestController: RequestController
  history: any

  constructor(history) {
    this.sharedDataProvider = new SharedDataProvider()
    this.toastController = new ToastController()
    this.requestController = new RequestController(history)
    this.history = history
  }

  public async createNewMachine(machine: Machine, machinePicture, history) {
    machine.dateOfEntry = new Date()
    let machineObject = {
      machine: machine,
      machinePicture: machinePicture,
    }
    let path = this.sharedDataProvider.host + '/machines/createNewMachine'
    let data = await this.requestController.postRequest(path, machineObject)

    // show toast
    this.toastController.showToast_createNewMachine(machine)

    this.history.push(`/machine/${data}`)
    return data
  }

  public async getCompanyById(id): Promise<Company> {
    let path =
      this.sharedDataProvider.host + '/companies/getCompanyById?id=' + id
    let data = await this.requestController.getRequest(path)
    return data
  }

  public async getMachineTypeById(id): Promise<MachineType> {
    let path =
      this.sharedDataProvider.host + '/machineTypes/getMachineTypeById?id=' + id
    let data = await this.requestController.getRequest(path)
    return data
  }
}
