import { SharedDataProvider } from '../../providers/SharedDataProvider'
import 'regenerator-runtime'
import { Machine } from '../../../machines/models/Machine'
import { ToastController } from '../../controllers/ToastController'
import { AuthenticationController } from '../../controllers/AuthenticationController'
import { RequestController } from '../../controllers/RequestController'
const download = require('downloadjs')

interface IGetMachineFiles {
  machineFiles: string[]
  machineTypeFiles: string[]
}

export class MachineFileController {
  sharedDataProvider: SharedDataProvider
  toastController: ToastController
  requestController: RequestController

  constructor(history) {
    this.sharedDataProvider = new SharedDataProvider()
    this.toastController = new ToastController()
    this.requestController = new RequestController(history)
  }

  public async getMachineFiles(path: string[], id: string): Promise<string[]> {
    const pathString = path.join('/')

    let p =
      this.sharedDataProvider.host +
      '/machines/getAllMachineFiles?pathString=' +
      pathString +
      '&id=' +
      id
    let data = await this.requestController.getRequest(p)
    return data
  }

  public async downloadMachineFile(path: string[], fileName, id: string) {
    const pathString = path.join('/')

    let response = await fetch(
      this.sharedDataProvider.host +
        '/machines/downloadMachineFile?id=' +
        id +
        '&pathString=' +
        pathString +
        '&fileName=' +
        fileName,
      {
        headers: new Headers({
          accesstoken: 'Bearer ' + window.localStorage.getItem('accesstoken'),
          refreshtoken: 'Bearer ' + window.localStorage.getItem('refreshtoken'),
        }),
      }
    )

    const blob = await response.blob()
    download(blob, fileName)
  }

  public getMachineFilePreviewImageUrl(path: string[], fileName, id: string) {
    const pathString = path.join('/')

    const url =
      this.sharedDataProvider.host +
      '/machines/getMachineFilePreviewImageUrl?id=' +
      id +
      '&pathString=' +
      pathString +
      '&fileName=' +
      fileName +
      '&' +
      new Date()

    return url
  }

  public async openMachineFile(path: string[], fileName, id: string) {
    const pathString = path.join('/')

    let response = await fetch(
      this.sharedDataProvider.host +
        '/machines/downloadMachineFile?id=' +
        id +
        '&pathString=' +
        pathString +
        '&fileName=' +
        fileName,
      {
        headers: new Headers({
          accesstoken: 'Bearer ' + window.localStorage.getItem('accesstoken'),
          refreshtoken: 'Bearer ' + window.localStorage.getItem('refreshtoken'),
        }),
      }
    )

    const blob = await response.blob()

    const fileURL = URL.createObjectURL(blob)
    const pdfWindow = window.open()
    pdfWindow.location.href = fileURL
  }

  public async uploadMachineFile(path: string[], fileData, fileName, id) {
    const pathString = path.join('/')

    let machineTypeFileObject = {
      pathString: pathString,
      fileData: fileData,
      fileName: fileName,
    }
    let response = await fetch(
      this.sharedDataProvider.host + '/machines/uploadMachineFile?id=' + id,
      {
        method: 'POST',
        headers: new Headers({
          accesstoken: 'Bearer ' + window.localStorage.getItem('accesstoken'),
          refreshtoken: 'Bearer ' + window.localStorage.getItem('refreshtoken'),
          'Content-Type': 'application/json;charset=utf-8',
        }),
        body: JSON.stringify(machineTypeFileObject),
      }
    )

    let responseData = await response.json()

    let authenticationController = new AuthenticationController()
    authenticationController.updateAuthenticationData({
      newTokensGenerated: responseData.newTokensGenerated,
      newAccessToken: responseData.newAccessToken,
      newRefreshToken: responseData.newRefreshToken,
    })
  }

  public async deleteMachineFile(path: string[], fileName, id) {
    const pathString = path.join('/')

    let p =
      this.sharedDataProvider.host +
      '/machines/deleteMachineFile?id=' +
      id +
      '&pathString=' +
      pathString +
      '&fileName=' +
      fileName
    let data = await this.requestController.getRequest(p)
    return data
  }

  public async renameFile(path: string[], oldFileName, newFileName, id) {
    const pathString = path.join('/')

    let p =
      this.sharedDataProvider.host +
      '/machines/renameFile?id=' +
      id +
      '&pathString=' +
      pathString +
      '&oldFileName=' +
      oldFileName +
      '&newFileName=' +
      newFileName
    let data = await this.requestController.getRequest(p)
    return data
  }

  public async createNewFolder(path: string[], id, name) {
    const pathString = path.join('/')

    let p =
      this.sharedDataProvider.host +
      '/machines/createNewFolder?id=' +
      id +
      '&pathString=' +
      pathString +
      '&name=' +
      name
    let data = await this.requestController.getRequest(p)
    return data
  }
}
