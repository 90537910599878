import {
  createStyles,
  FormControl,
  InputLabel,
  makeStyles,
  Select,
  TextField,
  Theme,
} from '@material-ui/core'
import React from 'react'
import { SharedDataProvider } from '../../../shared/providers/SharedDataProvider'
import { Company } from '../../models/Company'
import { MachineType } from '../../models/MachineType'
import { TermsController } from '../../../shared/controllers/ TermsController'

interface MachineTypeMainDataProps {
  change
  machineType: MachineType
  selectedLanguage
}

export const MachineTypeMainData = ({
  change,
  machineType,
  selectedLanguage,
}: MachineTypeMainDataProps) => {
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      dataContainer: {
        marginTop: 30,
        marginLeft: 20,
        marginRight: 20,
      },
      textfield: {
        margin: 0,
        backgroundColor: 'white',
        width: 200,
      },
      seperator: {
        borderBottom: '1px solid lightgrey',
        marginTop: '10px',
        marginBottom: '10px',
      },
      heading: {
        color: '#212121',
        fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
        fontSize: 18,
        textAlign: 'center',
        marginBottom: 10,
        marginTop: 50,
      },
      companyAndMachineTypeContainer: {
        display: 'flex',
        justifyContent: 'center',
      },
      companyAndMachineTypeDisplay: {
        display: 'flex',
        flexDirection: 'column',
        marginRight: 20,
        marginLeft: 20,
      },
      companyLogo: {
        objectFit: 'contain',
        objectPosition: 'center',
        height: '120px',
        width: '180px',
      },
      machineTypePicture: {
        objectFit: 'contain',
        objectPosition: 'center',
        height: '120px',
        borderRadius: '50%',
      },
    })
  )
  const classes = useStyles()

  const sharedDataProvider = new SharedDataProvider()

  const termsController = new TermsController()

  return (
    <div>
      <div className={classes.heading}>
        {termsController.getTerm('Maschinentypdaten', selectedLanguage) ||
          {
            de: 'Maschinentypdaten',
            en: 'Machine Type Main Data',
            fr: 'Maschinentypdaten - FR',
          }[selectedLanguage]}
      </div>
      <div className={classes.seperator}></div>

      <div className={classes.companyAndMachineTypeContainer}>
        <div className={classes.companyAndMachineTypeDisplay}>
          <img
            className={classes.companyLogo}
            src={
              sharedDataProvider.host +
              '/companies/getCompanyLogo?id=' +
              machineType.company +
              '&' +
              new Date()
            }
          ></img>
        </div>
      </div>

      <div className={classes.dataContainer}>
        <TextField
          disabled={
            window.localStorage.getItem('loggedInUserRole') ===
            'manufacturerAdminLight'
          }
          className={classes.textfield}
          size="small"
          id="name"
          label="Name"
          variant="filled"
          defaultValue={machineType.name}
          onBlur={(e) => change(e.target.id, e.target.value as string)}
        />
      </div>
    </div>
  )
}
