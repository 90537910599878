import { SharedDataProvider } from '../../../../shared/providers/SharedDataProvider'
import 'regenerator-runtime'
import { Company } from '../../../models/Company'
import { ToastContainer } from 'react-toastify'
import { ToastController } from '../../../../shared/controllers/ToastController'
import { User } from '../../../../users/models/User'
import { AuthenticationController } from '../../../../shared/controllers/AuthenticationController'
import { RequestController } from '../../../../shared/controllers/RequestController'

export class CompanyPageController {
  sharedDataProvider: SharedDataProvider
  toastController: ToastController
  requestController: RequestController
  history: any

  constructor(history) {
    this.sharedDataProvider = new SharedDataProvider()
    this.toastController = new ToastController()
    this.requestController = new RequestController(history)
    this.history = history
  }

  public async getCompanyById(id): Promise<Company> {
    let path =
      this.sharedDataProvider.host + '/companies/getCompanyById?id=' + id
    let data = await this.requestController.getRequest(path)
    return data
  }

  public async saveCompany(
    company: Company,
    companyLogo,
    companyBackground,
    history
  ) {
    let companyObject = {
      company: company,
      companyLogo: companyLogo,
      companyBackground: companyBackground,
    }
    let path = this.sharedDataProvider.host + '/companies/saveCompany'
    let data = await this.requestController.postRequest(path, companyObject)

    // show toast
    this.toastController.showToast_saveCompany(company)

    this.history.push(`/company/${company._id}`)
  }

  public async deleteCompany(company: Company, history) {
    let path =
      this.sharedDataProvider.host +
      '/companies/deleteCompany?id=' +
      company._id
    let data = await this.requestController.getRequest(path)

    // show toast
    this.toastController.showToast_deleteCompany(company)

    this.history.push(`/companies`)
  }

  public async getPossibleContactPersons(id): Promise<User[]> {
    let path =
      this.sharedDataProvider.host +
      '/users/getPossibleContactPersonsByCompany?companyId=' +
      id
    let possibleContactPersons = await this.requestController.getRequest(path)

    possibleContactPersons.push({
      id: '',
      name: '',
    })

    return possibleContactPersons
  }
}
