import { SharedDataProvider } from '../../../../shared/providers/SharedDataProvider'
import 'regenerator-runtime'
import { Company } from '../../../models/Company'
import { ToastController } from '../../../../shared/controllers/ToastController'
import { AuthenticationController } from '../../../../shared/controllers/AuthenticationController'
import { RequestController } from '../../../../shared/controllers/RequestController'

export class CreateNewCompanyController {
  sharedDataProvider: SharedDataProvider
  toastController: ToastController
  requestController: RequestController
  history: any

  constructor(history) {
    this.sharedDataProvider = new SharedDataProvider()
    this.toastController = new ToastController()
    this.requestController = new RequestController(history)
    this.history = history
  }

  public async createNewCompany(
    company: Company,
    companyLogo,
    companyBackground,
    history
  ) {
    company.dateOfEntry = new Date()
    let companyObject = {
      company: company,
      companyLogo: companyLogo,
      companyBackground: companyBackground,
    }
    let path = this.sharedDataProvider.host + '/companies/createNewCompany'
    let data = await this.requestController.postRequest(path, companyObject)

    // show toast
    this.toastController.showToast_createNewCompany(company)

    this.history.push(`/company/${data}`)
    return data
  }
}
