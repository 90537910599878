import { SharedDataProvider } from '../../../../shared/providers/SharedDataProvider'
import 'regenerator-runtime'
import { Machine } from '../../../models/Machine'
import { ToastController } from '../../../../shared/controllers/ToastController'
import { Company } from '../../../models/Company'
import { MachineType } from '../../../models/MachineType'
import { AuthenticationController } from '../../../../shared/controllers/AuthenticationController'
import { RequestController } from '../../../../shared/controllers/RequestController'

export class MachinePageController {
  sharedDataProvider: SharedDataProvider
  toastController: ToastController
  requestController: RequestController
  history: any

  constructor(history) {
    this.sharedDataProvider = new SharedDataProvider()
    this.toastController = new ToastController()
    this.requestController = new RequestController(history)
    this.history = history
  }

  public async getMachineById(machineId): Promise<Machine> {
    let path =
      this.sharedDataProvider.host + '/machines/getMachineById?id=' + machineId
    let data = await this.requestController.getRequest(path)
    return data
  }

  public async saveMachine(machine: Machine, machinePicture, history) {
    let machineObject = {
      machine: machine,
      machinePicture: machinePicture,
    }
    let path = this.sharedDataProvider.host + '/machines/saveMachine'
    let data = await this.requestController.postRequest(path, machineObject)

    // show toast
    this.toastController.showToast_saveMachine(machine)

    this.history.push(`/machine/${machine._id}`)
  }

  public async deleteMachine(machine: Machine, history) {
    let path =
      this.sharedDataProvider.host + '/machines/deleteMachine?id=' + machine._id
    let data = await this.requestController.getRequest(path)

    // show toast
    this.toastController.showToast_deleteMachine(machine)

    this.history.push(`/machines/${machine.company}/${machine.machineType}`)
  }

  public async getMachineTypeById(id): Promise<MachineType> {
    let path =
      this.sharedDataProvider.host + '/machineTypes/getMachineTypeById?id=' + id
    let data = await this.requestController.getRequest(path)
    return data
  }
}
