import { SharedDataProvider } from '../../providers/SharedDataProvider'
import 'regenerator-runtime'
import { MachineType } from '../../../machineTypes/models/MachineType'
import { ToastController } from '../../controllers/ToastController'
import { AuthenticationController } from '../../controllers/AuthenticationController'
import { RequestController } from '../../controllers/RequestController'
const download = require('downloadjs')

export class MachineTypeFileController {
  sharedDataProvider: SharedDataProvider
  toastController: ToastController
  requestController: RequestController

  constructor(history) {
    this.sharedDataProvider = new SharedDataProvider()
    this.toastController = new ToastController()
    this.requestController = new RequestController(history)
  }

  public async getMachineTypeFiles(
    path: string[],
    id: string
  ): Promise<string[]> {
    const pathString = path.join('/')

    let p =
      this.sharedDataProvider.host +
      '/machineTypes/getAllMachineTypeFiles?pathString=' +
      pathString +
      '&id=' +
      id
    let data = await this.requestController.getRequest(p)
    return data
  }

  public async downloadMachineTypeFile(path: string[], fileName, id: string) {
    const pathString = path.join('/')

    let response = await fetch(
      this.sharedDataProvider.host +
        '/machineTypes/downloadMachineTypeFile?id=' +
        id +
        '&pathString=' +
        pathString +
        '&fileName=' +
        fileName,
      {
        headers: new Headers({
          accesstoken: 'Bearer ' + window.localStorage.getItem('accesstoken'),
          refreshtoken: 'Bearer ' + window.localStorage.getItem('refreshtoken'),
        }),
      }
    )

    const blob = await response.blob()
    download(blob, fileName)
  }

  public getMachineTypeFilePreviewImageUrl(
    path: string[],
    fileName,
    id: string
  ) {
    const pathString = path.join('/')

    return (
      this.sharedDataProvider.host +
      '/machineTypes/getMachineTypeFilePreviewImageUrl?id=' +
      id +
      '&pathString=' +
      pathString +
      '&fileName=' +
      fileName +
      '&' +
      new Date()
    )
  }

  public async openMachineTypeFile(path: string[], fileName, id: string) {
    const pathString = path.join('/')

    let response = await fetch(
      this.sharedDataProvider.host +
        '/machineTypes/downloadMachineTypeFile?id=' +
        id +
        '&pathString=' +
        pathString +
        '&fileName=' +
        fileName,
      {
        headers: new Headers({
          accesstoken: 'Bearer ' + window.localStorage.getItem('accesstoken'),
          refreshtoken: 'Bearer ' + window.localStorage.getItem('refreshtoken'),
        }),
      }
    )

    const blob = await response.blob()

    const fileURL = URL.createObjectURL(blob)
    const pdfWindow = window.open()
    pdfWindow.location.href = fileURL
  }

  public async uploadMachineTypeFile(path: string[], fileData, fileName, id) {
    const pathString = path.join('/')

    let machineTypeFileObject = {
      pathString: pathString,
      fileData: fileData,
      fileName: fileName,
    }
    let response = await fetch(
      this.sharedDataProvider.host +
        '/machineTypes/uploadMachineTypeFile?id=' +
        id,
      {
        method: 'POST',
        headers: new Headers({
          accesstoken: 'Bearer ' + window.localStorage.getItem('accesstoken'),
          refreshtoken: 'Bearer ' + window.localStorage.getItem('refreshtoken'),
          'Content-Type': 'application/json;charset=utf-8',
        }),
        body: JSON.stringify(machineTypeFileObject),
      }
    )

    let responseData = await response.json()

    let authenticationController = new AuthenticationController()
    authenticationController.updateAuthenticationData({
      newTokensGenerated: responseData.newTokensGenerated,
      newAccessToken: responseData.newAccessToken,
      newRefreshToken: responseData.newRefreshToken,
    })
  }

  public async deleteMachineTypeFile(path: string[], fileName, id) {
    const pathString = path.join('/')

    let p =
      this.sharedDataProvider.host +
      '/machineTypes/deleteMachineTypeFile?id=' +
      id +
      '&pathString=' +
      pathString +
      '&fileName=' +
      fileName
    let data = await this.requestController.getRequest(p)
    return data
  }

  public async renameFile(path: string[], oldFileName, newFileName, id) {
    const pathString = path.join('/')

    let p =
      this.sharedDataProvider.host +
      '/machineTypes/renameFile?id=' +
      id +
      '&pathString=' +
      pathString +
      '&oldFileName=' +
      oldFileName +
      '&newFileName=' +
      newFileName
    let data = await this.requestController.getRequest(p)
    return data
  }

  public async createNewFolder(path: string[], id, name) {
    const pathString = path.join('/')

    let p =
      this.sharedDataProvider.host +
      '/machineTypes/createNewFolder?id=' +
      id +
      '&pathString=' +
      pathString +
      '&name=' +
      name
    let data = await this.requestController.getRequest(p)
    return data
  }
}
