import {
  createStyles,
  FormControl,
  InputLabel,
  makeStyles,
  Select,
  TextField,
  Theme,
} from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { User } from '../../../users/models/User'

interface CompanyMainDataProps {
  company
  change
}

export const CompanyMainData = ({ company, change }: CompanyMainDataProps) => {
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      dataContainer: {
        marginTop: 30,
        marginLeft: 20,
        marginRight: 20,
      },
      textfield: {
        margin: 0,
        backgroundColor: 'white',
        width: 200,
      },
    })
  )
  const classes = useStyles()

  return (
    <div>
      <div className={classes.dataContainer}>
        <TextField
          className={classes.textfield}
          size="small"
          id="name"
          label="Name"
          variant="filled"
          defaultValue={company.name}
          onBlur={(e) => change(e.target.id, e.target.value as string)}
        />
      </div>

      <div className={classes.dataContainer}>
        <TextField
          className={classes.textfield}
          size="small"
          id="country"
          label="Land"
          variant="filled"
          defaultValue={company.country}
          onBlur={(e) => change(e.target.id, e.target.value as string)}
        />
      </div>

      <div className={classes.dataContainer}>
        <TextField
          className={classes.textfield}
          size="small"
          id="city"
          label="Stadt"
          variant="filled"
          defaultValue={company.city}
          onBlur={(e) => change(e.target.id, e.target.value as string)}
        />
      </div>

      <div className={classes.dataContainer}>
        <TextField
          className={classes.textfield}
          size="small"
          id="postalCode"
          label="PLZ"
          variant="filled"
          defaultValue={company.postalCode}
          onBlur={(e) => change(e.target.id, e.target.value as string)}
        />
      </div>

      <div className={classes.dataContainer}>
        <TextField
          className={classes.textfield}
          size="small"
          id="street"
          label="Straße"
          variant="filled"
          defaultValue={company.street}
          onBlur={(e) => change(e.target.id, e.target.value as string)}
        />
      </div>

      <div className={classes.dataContainer}>
        <TextField
          className={classes.textfield}
          size="small"
          id="streetNumber"
          label="Hausnr."
          variant="filled"
          defaultValue={company.streetNumber}
          onBlur={(e) => change(e.target.id, e.target.value as string)}
        />
      </div>

      <div className={classes.dataContainer}>
        <TextField
          className={classes.textfield}
          size="small"
          id="industry"
          label="Branche"
          variant="filled"
          multiline
          rows={4}
          defaultValue={company.industry}
          onBlur={(e) => change(e.target.id, e.target.value as string)}
        />
      </div>

      <div className={classes.dataContainer}>
        <TextField
          className={classes.textfield}
          size="small"
          id="customerContact"
          label="Kundenkontakt"
          variant="filled"
          defaultValue={company.customerContact}
          onBlur={(e) => change(e.target.id, e.target.value as string)}
        />
      </div>

      <div className={classes.dataContainer}>
        <TextField
          className={classes.textfield}
          size="small"
          id="customerContactPhone"
          label="Kundenkontakt Tel."
          variant="filled"
          defaultValue={company.customerContactPhone}
          onBlur={(e) => change(e.target.id, e.target.value as string)}
        />
      </div>

      <div className={classes.dataContainer}>
        <TextField
          className={classes.textfield}
          size="small"
          id="customerContactMail"
          label="Kundenkontakt Mail"
          variant="filled"
          defaultValue={company.customerContactMail}
          onBlur={(e) => change(e.target.id, e.target.value as string)}
        />
      </div>
    </div>
  )
}
