import { SharedDataProvider } from '../../../../shared/providers/SharedDataProvider'
import 'regenerator-runtime'
import { AuthenticationController } from '../../../../shared/controllers/AuthenticationController'
import { RequestController } from '../../../../shared/controllers/RequestController'

export class LoginController {
  sharedDataProvider: SharedDataProvider
  requestController: RequestController

  constructor(history) {
    this.sharedDataProvider = new SharedDataProvider()
    this.requestController = new RequestController(history)
  }

  public async login(mail, password) {
    let response = await fetch(
      this.sharedDataProvider.host +
        '/users/login?mail=' +
        mail +
        '&password=' +
        password
    )

    if (response.status === 200) {
      let responseData = await response.json()

      let authenticationController = new AuthenticationController()
      authenticationController.updateAuthenticationData({
        newTokensGenerated: responseData.newTokensGenerated,
        newAccessToken: responseData.newAccessToken,
        newRefreshToken: responseData.newRefreshToken,
      })

      return { status: 200, data: responseData }
    } else {
      return { status: 403, data: {} }
    }
  }
}
