import React from 'react'
import { CircularProgress } from '@material-ui/core'

export const LoadingAnimation = () => {
  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <CircularProgress />
    </div>
  )
}
