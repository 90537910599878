import {
  createStyles,
  FormControl,
  InputLabel,
  makeStyles,
  Select,
  TextField,
  Theme,
} from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { CompanyAssignmentController } from '../controllers/CompanyAssignmentController'
import { SharedDataProvider } from '../../../shared/providers/SharedDataProvider'
import { UtilController } from '../../../shared/controllers/UtilController'

interface UserMainDataProps {
  user
  change
  history
  selectedLanguage
}

export const UserMainData = ({
  user,
  change,
  history,
  selectedLanguage,
}: UserMainDataProps) => {
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      dataContainer: {
        marginTop: 30,
        marginLeft: 20,
        marginRight: 20,
      },
      textfield: {
        margin: 0,
        backgroundColor: 'white',
        width: 200,
      },
    })
  )
  const classes = useStyles()

  const sharedDataProvider = new SharedDataProvider()

  const companyAssignmentController = new CompanyAssignmentController(history)
  const utilController = new UtilController()

  let [accessibleCompaniesAndIds, setAccessibleCompaniesAndIds] = useState([])
  let [accessibleRoles, setAccessibleRoles] = useState([])
  let [accessibleUnlockLevels, setAccessibleUnlockLevels] = useState([])

  useEffect(() => {
    // get for logged in user accessible roles
    let roles = sharedDataProvider.getAccessibleRoles(user)
    setAccessibleRoles(roles)

    let unlockLevels = sharedDataProvider.getAccessibleUnlockLevels()
    setAccessibleUnlockLevels(unlockLevels)

    // in case the users gets freshly created, set the role and company to first possible option
    if (user.role === '') {
      change('role', roles[0].id)
    } else {
      // hack to make select field update - value and label otherwise overlap
      let role = user.role
      change('role', '')
      change('role', role)
    }
  }, [])

  useEffect(() => {
    // fetch companies and more data from backend
    let getData = async () => {
      // companies and Ids
      let accessibleCompaniesAndIds = await companyAssignmentController.getAccessibleCompaniesAndIds()
      setAccessibleCompaniesAndIds(accessibleCompaniesAndIds)

      if (user.company === '') {
        change('company', accessibleCompaniesAndIds[0].id)
      } else {
        // hack to make select field update - value and label otherwise overlap
        let company = user.company
        change('company', '')
        change('company', company)
      }
    }
    getData()
  }, [])

  return (
    <div>
      <div className={classes.dataContainer}>
        <TextField
          error={user.firstName === ''}
          className={classes.textfield}
          size="small"
          id="firstName"
          label="Vorname"
          variant="filled"
          defaultValue={user.firstName}
          onBlur={(e) => change(e.target.id, e.target.value as string)}
        />
      </div>

      <div className={classes.dataContainer}>
        <TextField
          error={user.lastName === ''}
          className={classes.textfield}
          size="small"
          id="lastName"
          label="Nachname"
          variant="filled"
          defaultValue={user.lastName}
          onBlur={(e) => change(e.target.id, e.target.value as string)}
        />
      </div>

      <div className={classes.dataContainer}>
        <TextField
          error={!utilController.validateEmail(user.mail)}
          className={classes.textfield}
          size="small"
          id="mail"
          label="Mail"
          variant="filled"
          defaultValue={user.mail}
          onBlur={(e) => change(e.target.id, e.target.value as string)}
        />
      </div>

      {window.localStorage.getItem('loggedInUserRole') === 'admin' ? (
        <div className={classes.dataContainer}>
          <FormControl variant="filled" size="small">
            <InputLabel htmlFor="outlined-age-native-simple">
              {
                {
                  de: 'Unternehmen',
                  en: 'Company',
                  fr: 'Unternehmen - FR',
                }[selectedLanguage]
              }
            </InputLabel>
            <Select
              className={classes.textfield}
              native
              label="Company"
              value={user.company}
              onChange={(e) => change('company', e.target.value as string)}
              inputProps={{
                name: 'company',
                id: 'company',
              }}
            >
              {accessibleCompaniesAndIds.map((company) => (
                <option key={company.id} value={company.id}>
                  {company.name}
                </option>
              ))}
            </Select>
          </FormControl>
        </div>
      ) : null}

      {window.localStorage.getItem('loggedInUserRole') === 'admin' ||
      window.localStorage.getItem('loggedInUserRole') === 'manufacturerAdmin' ||
      window.localStorage.getItem('loggedInUserRole') ===
        'manufacturerAdminLight' ? (
        <div className={classes.dataContainer}>
          <FormControl variant="filled" size="small">
            <InputLabel htmlFor="outlined-age-native-simple">Rolle</InputLabel>
            <Select
              className={classes.textfield}
              native
              label="Rolle"
              value={user.role}
              onChange={(e) => change('role', e.target.value as string)}
              inputProps={{
                name: 'role',
                id: 'role',
              }}
              disabled={
                window.localStorage.getItem('loggedInUserRole') ===
                  'manufacturerAdminLight' && user.role === 'manufacturerAdmin'
              }
            >
              {accessibleRoles.map((role) => (
                <option key={role.id} value={role.id}>
                  {role.name}
                </option>
              ))}
            </Select>
          </FormControl>
        </div>
      ) : null}

      {window.localStorage.getItem('loggedInUserRole') === 'admin' ||
      window.localStorage.getItem('loggedInUserRole') === 'manufacturerAdmin' ||
      window.localStorage.getItem('loggedInUserRole') ===
        'manufacturerAdminLight' ? (
        <div className={classes.dataContainer}>
          <FormControl variant="filled" size="small">
            <InputLabel htmlFor="outlined-age-native-simple">
              Berechtigungs Level
            </InputLabel>
            <Select
              className={classes.textfield}
              native
              label="Berechtigungs Level"
              value={user.unlockLevel}
              onChange={(e) => change('unlockLevel', e.target.value as string)}
              inputProps={{
                name: 'unlockLevel',
                id: 'unlockLevel',
              }}
            >
              {accessibleUnlockLevels.map((role) => (
                <option key={role.id} value={role.id}>
                  {role.name}
                </option>
              ))}
            </Select>
          </FormControl>
        </div>
      ) : null}

      <div className={classes.dataContainer}>
        <TextField
          className={classes.textfield}
          size="small"
          id="areaOfResponsibility"
          label="Zuständigkeit"
          variant="filled"
          defaultValue={user.areaOfResponsibility}
          onBlur={(e) => change(e.target.id, e.target.value as string)}
        />
      </div>

      <div className={classes.dataContainer}>
        <TextField
          className={classes.textfield}
          size="small"
          id="phone"
          label="Tel. Nummer"
          variant="filled"
          defaultValue={user.phone}
          onBlur={(e) => change(e.target.id, e.target.value as string)}
        />
      </div>
    </div>
  )
}
