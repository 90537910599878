import {
  Button,
  createStyles,
  FormControl,
  InputLabel,
  makeStyles,
  Select,
  TextField,
  Theme,
} from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import React, { useEffect, useState } from 'react'
import { Footer } from '../../../../shared/components/Footer'
import { Header } from '../../../../shared/components/Header'
import { LoadingAnimation } from '../../../../shared/components/LoadingAnimation'
import { SharedDataProvider } from '../../../../shared/providers/SharedDataProvider'
import { CompanyCodesController } from '../controllers/CompanyCodesController'

export const CompanyCodes = (props) => {
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      textfieldCompanyCodes: {
        margin: 0,
        backgroundColor: 'white',
        width: 1000,
      },
      appContent: {
        minHeight: '86vh',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        flexWrap: 'wrap',
        alignItems: 'center',
      },
      dataContainer: {
        marginTop: 30,
        marginLeft: 20,
        marginRight: 20,
      },
      button: {
        width: 200,
        borderRadius: '10px',
        backgroundColor: '#1c8033',
        color: 'white',
        variant: 'contained',
        margin: 5,
        fontSize: 15,
        '&:hover': {
          backgroundColor: '#1c8033',
        },
      },
    })
  )
  const classes = useStyles()

  const sharedDataProvider = new SharedDataProvider()

  const companyCodesController: CompanyCodesController = new CompanyCodesController(
    props.history
  )

  const [isLoading, setIsLoading] = useState(true)

  let [companyCodes, setCompanyCodes] = useState('')

  useEffect(() => {
    setIsLoading(true)
    let getData = async () => {
      let res = await companyCodesController.getCompanyCodes()
      setCompanyCodes(JSON.stringify(res, null, 2))
      setIsLoading(false)
    }
    getData()
  }, [])

  return (
    <div>
      <Header
        page={'companyCodes'}
        history={props.history}
        selectedLanguage={props.selectedLanguage}
        setSelectedLanguage={props.setSelectedLanguage}
      />

      {isLoading ? (
        <div className={classes.appContent}>
          <div className={classes.dataContainer}>
            <LoadingAnimation />
          </div>
        </div>
      ) : (
        <div className={classes.appContent}>
          <div className={classes.dataContainer}>
            <TextField
              className={classes.textfieldCompanyCodes}
              size="small"
              id="companyCodes"
              label="Company Codes"
              variant="filled"
              multiline
              rows={70}
              defaultValue={companyCodes}
              onBlur={(e) => setCompanyCodes(e.target.value as string)}
            />
          </div>
          <div className={classes.dataContainer}>
            <Button
              className={classes.button}
              onClick={async () => {
                companyCodesController.saveCompanyCodes(companyCodes)
              }}
            >
              {
                {
                  de: 'speichern',
                  en: 'save',
                  fr: 'speichern - FR',
                }[props.selectedLanguage]
              }
            </Button>
          </div>
        </div>
      )}

      <Footer
        history={props.history}
        selectedLanguage={props.selectedLanguage}
      />
    </div>
  )
}
