import React, { useEffect, useState } from 'react'
import {
  Button,
  createStyles,
  makeStyles,
  TextField,
  Theme,
} from '@material-ui/core'
import { User } from '../../../../../users/models/User'
import { Machine } from '../../../../models/Machine'
import { AccessManagementController } from '../../controllers/AccessManagementController'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { SharedDataProvider } from '../../../../../shared/providers/SharedDataProvider'

interface UsersWithAccessProps {
  usersWithAbilityToGetAccess
  setUsersWithAbilityToGetAccess
  usersWithAccess
  setUsersWithAccess
  selectedLanguage
}

export const UsersWithAccess = ({
  usersWithAbilityToGetAccess,
  setUsersWithAbilityToGetAccess,
  usersWithAccess,
  setUsersWithAccess,
  selectedLanguage,
}: UsersWithAccessProps) => {
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      dataContainer: {
        marginTop: 30,
        marginLeft: 20,
        marginRight: 20,
      },
      userDataContainer: {
        display: 'flex',
        alignItems: 'center',
        marginTop: 20,
      },
      userText: {
        color: '#212121',
        fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
        fontSize: 16,
        textAlign: 'left',
        marginRight: 10,
      },
      userButton: {},
      textfield: {
        margin: 0,
        width: 200,
        backgroundColor: 'white',
      },
      button: {
        width: 200,
        borderRadius: '10px',
        backgroundColor: '#1c8033',
        color: 'white',
        variant: 'contained',
        margin: 5,
        fontSize: 15,
        '&:hover': {
          backgroundColor: '#1c8033',
        },
      },
      userProfilePicture: {
        objectFit: 'contain',
        objectPosition: 'center',
        width: '75px',
        borderRadius: '50%',
        marginRight: 10,
      },
      addUserButton: {
        borderRadius: '10px',
        backgroundColor: '#1c8033',
        color: 'white',
        variant: 'contained',
        margin: 5,
        marginLeft: 'auto',
        fontSize: 15,
        '&:hover': {
          backgroundColor: '#1c8033',
        },
      },
      heading: {
        color: '#212121',
        fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
        fontSize: 18,
        textAlign: 'center',
        marginBottom: 10,
      },
      deleteUserButton: {
        borderRadius: '10px',
        backgroundColor: '#f44336',
        color: 'white',
        variant: 'contained',
        margin: 5,
        marginLeft: 'auto',
        fontSize: 15,
        '&:hover': {
          backgroundColor: '#d32215',
        },
      },
      seperator: {
        borderBottom: '1px solid lightgrey',
        marginTop: '10px',
        marginBottom: '10px',
      },
    })
  )
  const classes = useStyles()

  const sharedDataProvider = new SharedDataProvider()

  const [usersWithAccessSearchValue, setUsersWithAccessSearchValue] = useState(
    ''
  )

  return (
    <div>
      {usersWithAccess.length > 0 ? (
        <div className={classes.dataContainer}>
          <div className={classes.heading}>
            {
              {
                de: 'Nutzer MIT Zugriff',
                en: 'Users WITH Access',
                fr: 'Nutzer MIT Zugriff - FR',
              }[selectedLanguage]
            }
          </div>
          <TextField
            className={classes.textfield}
            size="small"
            name="name"
            id="usersWithAccessSearchValue"
            label={
              {
                de: 'Name',
                en: 'Name',
                fr: 'Name - FR',
              }[selectedLanguage]
            }
            variant="filled"
            value={usersWithAccessSearchValue}
            onChange={(e) => setUsersWithAccessSearchValue(e.target.value)}
          />
          {usersWithAccess
            .filter((user) =>
              (user.firstName + user.lastName).includes(
                usersWithAccessSearchValue
              )
            )
            .map((user, i) => (
              <div className={classes.userDataContainer}>
                <img
                  className={classes.userProfilePicture}
                  src={
                    sharedDataProvider.host +
                    '/users/getUserProfilePicture?id=' +
                    user._id +
                    '&' +
                    new Date()
                  }
                ></img>
                <div className={classes.userText}>
                  {user.firstName +
                    ' ' +
                    user.lastName +
                    ' (' +
                    sharedDataProvider.getRoleName(user.role) +
                    ')'}
                </div>
                <Button
                  className={classes.deleteUserButton}
                  onClick={() => {
                    // add user to users with ability to get permission
                    usersWithAbilityToGetAccess.push(usersWithAccess[i])
                    setUsersWithAbilityToGetAccess([
                      ...usersWithAbilityToGetAccess,
                    ])
                    // delete user from users with permission
                    usersWithAccess.splice(i, 1)
                    setUsersWithAccess([...usersWithAccess])
                  }}
                >
                  {
                    {
                      de: 'Zugriff entfernen',
                      en: 'Remove Access',
                      fr: 'Zugriff entfernen - FR',
                    }[selectedLanguage]
                  }
                </Button>
              </div>
            ))}
        </div>
      ) : null}
    </div>
  )
}
