import {
  Button,
  createStyles,
  makeStyles,
  TextField,
  Theme,
} from '@material-ui/core'
import { PhotoCamera } from '@material-ui/icons'
import React, { useEffect, useState } from 'react'
import { Footer } from '../../../../shared/components/Footer'
import { Header } from '../../../../shared/components/Header'
import { LoadingAnimation } from '../../../../shared/components/LoadingAnimation'
import { SharedDataProvider } from '../../../../shared/providers/SharedDataProvider'
import { CompanyPageController } from '../controllers/CompanyPageController'
import { ImageController } from '../../../../shared/controllers/ImageController'
import { Company, emptyCompany } from '../../../models/Company'
import { CompanyMainData } from '../../../shared/components/CompanyMainData'
import { CompanyLogo } from '../../../shared/components/CompanyLogo'
import { CompanyBackground } from '../../../shared/components/CompanyBackground'
import { ContactPerson } from './ContactPerson'
import { ToastController } from '../../../../shared/controllers/ToastController'

export const CompanyPage = (props) => {
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      textfieldCompanyCodes: {
        margin: 0,
        backgroundColor: 'white',
        width: 1000,
      },
      appContent: {
        minHeight: '86vh',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        flexWrap: 'wrap',
        alignItems: 'center',
      },
      dataContainer: {
        marginTop: 30,
        marginLeft: 20,
        marginRight: 20,
      },
      button: {
        width: '100%',
        borderRadius: '10px',
        backgroundColor: '#1c8033',
        color: 'white',
        variant: 'contained',
        margin: 5,
        fontSize: 15,
        '&:hover': {
          backgroundColor: '#1c8033',
        },
      },
      deleteButton: {
        width: '100%',
        borderRadius: '10px',
        backgroundColor: '#f44336',
        color: 'white',
        variant: 'contained',
        margin: 5,
        fontSize: 15,
        '&:hover': {
          backgroundColor: '#d32215',
        },
      },
      companyLogo: {
        objectFit: 'contain',
        objectPosition: 'center',
        height: '240px',
        width: '360px',
      },
      companyBackground: {
        objectFit: 'contain',
        objectPosition: 'center',
        width: '360px',
      },
      hiddenInput: {
        display: 'none',
      },
    })
  )
  const classes = useStyles()

  const sharedDataProvider = new SharedDataProvider()
  const toastController = new ToastController()

  const companyPageController: CompanyPageController = new CompanyPageController(
    props.history
  )

  const [isLoading, setIsLoading] = useState(true)
  let [company, setCompany] = useState<Company>({ ...emptyCompany })
  let [companyLogo, setCompanyLogo] = useState<string | ArrayBuffer>()
  let [companyBackground, setCompanyBackground] = useState<
    string | ArrayBuffer
  >()

  let [companyTerms, setCompanyTerms] = useState('')

  const change = (key: string, value: string) => {
    company[key] = value
    setCompany({ ...company })
  }

  useEffect(() => {
    setIsLoading(true)
    // fetch company from backend
    const { params } = props.match
    let getCompanyById = async () => {
      let company: Company = await companyPageController.getCompanyById(
        params.companyId
      )
      setCompany({ ...company })
      setCompanyTerms(JSON.stringify(company.companyTerms, null, 2))

      setIsLoading(false)
    }
    getCompanyById()
  }, [])

  return (
    <div>
      <Header
        page={'company'}
        history={props.history}
        selectedLanguage={props.selectedLanguage}
        setSelectedLanguage={props.setSelectedLanguage}
      />

      {isLoading ? (
        <div className={classes.appContent}>
          <div className={classes.dataContainer}>
            <LoadingAnimation />
          </div>
        </div>
      ) : (
        <div className={classes.appContent}>
          <CompanyLogo
            company={company}
            companyLogo={companyLogo}
            setCompanyLogo={setCompanyLogo}
            selectedLanguage={props.selectedLanguage}
          />

          <CompanyBackground
            company={company}
            companyBackground={companyBackground}
            setCompanyBackground={setCompanyBackground}
            selectedLanguage={props.selectedLanguage}
          />

          <CompanyMainData company={company} change={change} />

          <div className={classes.dataContainer}>
            <TextField
              className={classes.textfieldCompanyCodes}
              size="small"
              id="companyTerms"
              label="Company Terms"
              variant="filled"
              multiline
              rows={20}
              defaultValue={companyTerms}
              onBlur={(e) => setCompanyTerms(e.target.value as string)}
            />
          </div>

          {company.contactPerson !== '' ? (
            <ContactPerson
              company={company}
              history={props.history}
              selectedLanguage={props.selectedLanguage}
            />
          ) : null}

          <div className={classes.dataContainer}>
            <Button
              className={classes.button}
              onClick={() => {
                try {
                  let parsedCompanyTerms = JSON.parse(companyTerms)

                  company.companyTerms = parsedCompanyTerms
                  companyPageController.saveCompany(
                    company,
                    companyLogo,
                    companyBackground,
                    props.history
                  )
                } catch (error) {
                  toastController.showToast_companyTermsError()
                }
              }}
            >
              {
                {
                  de: 'speichern',
                  en: 'save',
                  fr: 'speichern - FR',
                }[props.selectedLanguage]
              }
            </Button>
          </div>

          <div className={classes.dataContainer}>
            <Button
              className={classes.deleteButton}
              onClick={async () => {
                let confirmation = await confirm(
                  {
                    de: 'Unternehmen wirklich löschen?',
                    en: 'Delete company?',
                    fr: 'Unternehmen wirklich löschen? - FR',
                  }[props.selectedLanguage]
                )
                if (confirmation === true) {
                  companyPageController.deleteCompany(company, props.history)
                }
              }}
            >
              {
                {
                  de: 'löschen',
                  en: 'delete',
                  fr: 'löschen - FR',
                }[props.selectedLanguage]
              }
            </Button>
          </div>
        </div>
      )}
      <Footer
        history={props.history}
        selectedLanguage={props.selectedLanguage}
      />
    </div>
  )
}
