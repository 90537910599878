import { SharedDataProvider } from '../../../../shared/providers/SharedDataProvider'
import 'regenerator-runtime'
import { emptyMachineType, MachineType } from '../../../models/MachineType'
import { Company } from '../../../models/Company'
import { AuthenticationController } from '../../../../shared/controllers/AuthenticationController'
import { RequestController } from '../../../../shared/controllers/RequestController'

export class MachineSearchController {
  sharedDataProvider: SharedDataProvider
  requestController: RequestController

  constructor(history) {
    this.sharedDataProvider = new SharedDataProvider()
    this.requestController = new RequestController(history)
  }

  public async searchForMachines(
    machineTypeId,
    searchValue,
    searchProperty,
    searchIndexFrom,
    searchIndexTo
  ) {
    let path =
      this.sharedDataProvider.host +
      '/machines/' +
      this.getRouteMachines(searchProperty) +
      '?' +
      'machineTypeId=' +
      machineTypeId +
      '&' +
      searchProperty +
      '=' +
      searchValue +
      '&searchIndexFrom=' +
      searchIndexFrom +
      '&searchIndexTo=' +
      searchIndexTo
    let data = await this.requestController.getRequest(path)
    return data
  }

  private getRouteMachines(searchProperty) {
    if (searchProperty === 'machineNumber') {
      return 'getMachinesByMachineNumber'
    }
  }

  public async getAllCompanies() {
    let path = this.sharedDataProvider.host + '/companies/getAllCompanies'
    let data = await this.requestController.getRequest(path)
    return data
  }

  public async getMachineTypesByCompany(companyId) {
    let path =
      this.sharedDataProvider.host +
      '/machineTypes/getMachineTypesByCompany' +
      '?id=' +
      companyId
    let data = await this.requestController.getRequest(path)
    return data
  }

  public async getMachineTypeById(id): Promise<MachineType> {
    let path =
      this.sharedDataProvider.host + '/machineTypes/getMachineTypeById?id=' + id
    let data = await this.requestController.getRequest(path)
    return data
  }
}
