export interface MachineType {
  _id?: string
  dateOfEntry?: Date
  // Maschinen Typ
  name: string
  // ID der Firma
  company: string
}

export let emptyMachineType: MachineType = {
  name: '',
  company: '',
}
