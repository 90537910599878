import { Button, createStyles, makeStyles, Theme } from '@material-ui/core'
import { PhotoCamera } from '@material-ui/icons'
import React from 'react'
import { SharedDataProvider } from '../../../shared/providers/SharedDataProvider'
import { ImageController } from '../../../shared/controllers/ImageController'
import { Machine } from '../../models/Machine'
import { TermsController } from '../../../shared/controllers/ TermsController'

interface MachinePictureProps {
  machine: Machine
  machinePicture
  setMachinePicture
  selectedLanguage
}

export const MachinePicture = ({
  machine,
  machinePicture,
  setMachinePicture,
  selectedLanguage,
}: MachinePictureProps) => {
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      appContent: {
        minHeight: '86vh',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        flexWrap: 'wrap',
        alignItems: 'center',
      },
      dataContainer: {
        marginTop: 30,
        marginLeft: 20,
        marginRight: 20,
      },
      button: {
        width: '100%',
        borderRadius: '10px',
        backgroundColor: '#1c8033',
        color: 'white',
        variant: 'contained',
        margin: 5,
        fontSize: 15,
        '&:hover': {
          backgroundColor: '#1c8033',
        },
      },
      deleteButton: {
        width: '100%',
        borderRadius: '10px',
        backgroundColor: '#f44336',
        color: 'white',
        variant: 'contained',
        margin: 5,
        fontSize: 15,
        '&:hover': {
          backgroundColor: '#d32215',
        },
      },
      machinePicture: {
        objectFit: 'contain',
        objectPosition: 'center',
        width: '240px',
        borderRadius: '50%',
      },
      hiddenInput: {
        display: 'none',
      },
    })
  )
  const classes = useStyles()

  const sharedDataProvider = new SharedDataProvider()
  const imageController: ImageController = new ImageController()

  async function imageUpload() {
    // get image
    let files = document.getElementById('imageUpload').files

    // transform image into base64
    // prepare image by resizing etc
    let file = await imageController.prepareImageForUploadSquared(files[0])
    let reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => {
      let base64 = reader.result
      setMachinePicture(base64)
    }
  }

  const termsController = new TermsController()

  return (
    <div>
      <div className={classes.dataContainer}>
        <img
          className={classes.machinePicture}
          src={
            machinePicture
              ? machinePicture
              : sharedDataProvider.host +
                '/machines/getMachinePicture?id=' +
                machine._id +
                '&' +
                new Date()
          }
        ></img>

        <input
          className={classes.hiddenInput}
          type="file"
          id="imageUpload"
          accept="image/*"
          onChange={(e) => imageUpload()}
        />
      </div>

      <div className={classes.dataContainer}>
        <label htmlFor="imageUpload">
          <Button
            className={classes.button}
            component="span"
            startIcon={<PhotoCamera />}
          >
            {termsController.getTerm(
              'Maschinenbild uploaden',
              selectedLanguage
            ) ||
              {
                de: 'Maschinenbild uploaden',
                en: 'Upload Machine Picture',
                fr: 'Maschinenbild uploaden - FR',
              }[selectedLanguage]}
          </Button>
        </label>
      </div>
    </div>
  )
}
