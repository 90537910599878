export interface Machine {
  _id?: string
  dateOfEntry?: Date
  // Maschinen Typ
  machineType: string
  // Maschinen Nummer
  machineNumber: string
  //ID der Firma
  company: string
  // Liste von User-IDs, die auf die Maschine zugreifen dürfen
  usersWithAccess: string[]
  // Produktionsdatum
  dateOfProduction: Date
  // Datum der Erstzulassung
  dateOfInitialRegistration: Date
}

export let emptyMachine: Machine = {
  machineType: '',
  machineNumber: '',
  company: '',
  usersWithAccess: [],
  dateOfProduction: '',
  dateOfInitialRegistration: '',
}
