import { SharedDataProvider } from '../../../../shared/providers/SharedDataProvider'
import 'regenerator-runtime'
import { User } from '../../../models/User'
import { RequestController } from '../../../../shared/controllers/RequestController'

export class HomeController {
  sharedDataProvider: SharedDataProvider
  requestController: RequestController

  constructor(history) {
    this.sharedDataProvider = new SharedDataProvider()
    this.requestController = new RequestController(history)
  }

  public async getUserById(id): Promise<User> {
    let path = this.sharedDataProvider.host + '/users/getUserById?id=' + id
    let data = await this.requestController.getRequest(path)
    return data
  }
}
