import { Button, createStyles, makeStyles, Theme } from '@material-ui/core'
import React, { useState } from 'react'
import { CreateNewUserController } from '../controllers/CreateNewUserController'
import { ImageController } from '../../../../shared/controllers/ImageController'
import { Footer } from '../../../../shared/components/Footer'
import { Header } from '../../../../shared/components/Header'
import { UserMainData } from '../../../shared/components/UserMainData'
import { UserProfilePicture } from '../../../shared/components/UserProfilePicture'
import { UtilController } from '../../../../shared/controllers/UtilController'
import { User, emptyUser } from '../../../models/User'

export const CreateNewUser = (props) => {
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      appContent: {
        minHeight: '86vh',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        flexWrap: 'wrap',
        alignItems: 'center',
      },
      dataContainer: {
        marginTop: 30,
        marginLeft: 20,
        marginRight: 20,
      },
      button: {
        width: '100%',
        borderRadius: '10px',
        backgroundColor: '#1c8033',
        color: 'white',
        variant: 'contained',
        margin: 5,
        fontSize: 15,
        '&:hover': {
          backgroundColor: '#1c8033',
        },
      },
      userProfilePicture: {
        objectFit: 'contain',
        objectPosition: 'center',
        width: '240px',
        borderRadius: '50%',
      },
      hiddenInput: {
        display: 'none',
      },
    })
  )
  const classes = useStyles()

  const createNewUserController: CreateNewUserController = new CreateNewUserController(
    props.history
  )
  const imageController: ImageController = new ImageController()
  const utilController = new UtilController()

  let [user, setUser] = useState<User>({ ...emptyUser })
  let [userProfilePicture, setUserProfilePicture] = useState<
    string | ArrayBuffer
  >()

  const change = (key: string, value: string) => {
    user[key] = value
    setUser({ ...user })
  }

  return (
    <div>
      <Header
        page={'createNewUser'}
        history={props.history}
        selectedLanguage={props.selectedLanguage}
        setSelectedLanguage={props.setSelectedLanguage}
      />

      <div className={classes.appContent}>
        <UserProfilePicture
          user={user}
          userProfilePicture={userProfilePicture}
          setUserProfilePicture={setUserProfilePicture}
        />

        <UserMainData user={user} change={change} history={props.history} />

        <div className={classes.dataContainer}>
          <Button
            disabled={
              user.firstName === '' ||
              user.lastName === '' ||
              !utilController.validateEmail(user.mail)
            }
            className={classes.button}
            onClick={() => {
              createNewUserController.createNewUser(
                user,
                userProfilePicture,
                props.history
              )
            }}
          >
            {
              {
                de: 'User erstellen',
                en: 'create user',
                fr: 'User erstellen - FR',
              }[props.selectedLanguage]
            }
          </Button>
        </div>
      </div>

      <Footer
        history={props.history}
        selectedLanguage={props.selectedLanguage}
      />
    </div>
  )
}
