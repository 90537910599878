import { SharedDataProvider } from '../../../../shared/providers/SharedDataProvider'
import 'regenerator-runtime'
import { MachineType } from '../../../models/MachineType'
import { ToastController } from '../../../../shared/controllers/ToastController'
import { Company } from '../../../models/Company'
import { AuthenticationController } from '../../../../shared/controllers/AuthenticationController'
import { RequestController } from '../../../../shared/controllers/RequestController'

export class CreateNewMachineTypeController {
  sharedDataProvider: SharedDataProvider
  toastController: ToastController
  requestController: RequestController
  history: any

  constructor(history) {
    this.sharedDataProvider = new SharedDataProvider()
    this.toastController = new ToastController()
    this.requestController = new RequestController(history)
    this.history = history
  }

  public async createNewMachineType(
    machineType: MachineType,
    machineTypePicture,
    history
  ) {
    machineType.dateOfEntry = new Date()
    let machineTypeObject = {
      machineType: machineType,
      machineTypePicture: machineTypePicture,
    }
    let path =
      this.sharedDataProvider.host + '/machineTypes/createNewMachineType'
    let data = await this.requestController.postRequest(path, machineTypeObject)

    // show toast
    this.toastController.showToast_createNewMachineType(machineType)

    this.history.push(`/machineType/${data}`)
    return data
  }

  public async getCompanyById(id): Promise<Company> {
    let path =
      this.sharedDataProvider.host + '/companies/getCompanyById?id=' + id
    let data = await this.requestController.getRequest(path)
    return data
  }

  public async getMachineTypeById(id): Promise<MachineType> {
    let path =
      this.sharedDataProvider.host + '/machineTypes/getMachineTypeById?id=' + id
    let data = await this.requestController.getRequest(path)
    return data
  }
}
