import { SharedDataProvider } from '../../../../shared/providers/SharedDataProvider'
import 'regenerator-runtime'
import { emptyMachineType, MachineType } from '../../../models/MachineType'
import { Company } from '../../../models/Company'
import { AuthenticationController } from '../../../../shared/controllers/AuthenticationController'
import { RequestController } from '../../../../shared/controllers/RequestController'

export class MachineTypeSearchController {
  sharedDataProvider: SharedDataProvider
  requestController: RequestController

  constructor(history) {
    this.sharedDataProvider = new SharedDataProvider()
    this.requestController = new RequestController(history)
  }

  public async searchForMachineTypes(
    companyId,
    searchValue,
    searchProperty,
    searchIndexFrom,
    searchIndexTo
  ) {
    let path =
      this.sharedDataProvider.host +
      '/machineTypes/' +
      this.getRouteMachineTypes(searchProperty) +
      '?' +
      'companyId=' +
      companyId +
      '&' +
      searchProperty +
      '=' +
      searchValue +
      '&searchIndexFrom=' +
      searchIndexFrom +
      '&searchIndexTo=' +
      searchIndexTo
    let data = await this.requestController.getRequest(path)
    return data
  }

  private getRouteMachineTypes(searchProperty) {
    if (searchProperty === 'name') {
      return 'getMachineTypesByName'
    }
  }

  public async getAllCompanies() {
    let path = this.sharedDataProvider.host + '/companies/getAllCompanies'
    let data = await this.requestController.getRequest(path)
    return data
  }

  public async getMachineTypesByCompany(companyId) {
    let path =
      this.sharedDataProvider.host +
      '/machineTypes/getMachineTypesByCompany' +
      '?id=' +
      companyId
    let data = await this.requestController.getRequest(path)
    return data
  }

  public async getCompanyById(id): Promise<Company> {
    let path =
      this.sharedDataProvider.host + '/companies/getCompanyById?id=' + id
    let data = await this.requestController.getRequest(path)
    return data
  }

  public async getMachineTypeById(id): Promise<MachineType> {
    let path =
      this.sharedDataProvider.host + '/machineTypes/getMachineTypeById?id=' + id
    let data = await this.requestController.getRequest(path)
    return data
  }
}
