export interface User {
  _id?: string
  dateOfEntry?: Date
  //E-Mail Adresse mit der sich angemeldet werden kann
  mail: string
  //ID der Firma bei der er angestellt ist
  company: string
  //zB Admin, Hersteller-Admin, Techniker (die 3 Nutzer Levels)
  role: string
  //Vorname des Nutzers
  firstName: string
  //Nachname des Nutzers
  lastName: string
  //Telefon-Nummer
  phone: string
  //Freischaltstufen von Can-Master ( glaube 0-4)  ( Also ob er Parameter ändern darf etc.)
  unlockLevel: string
  //Bereich für welchen diese Person Zuständig ist, also im welchem Gebieten ( z.B.: Ostdeutschland oder in welchen PLZ Anfangsziffern  ….  )
  areaOfResponsibility: string
}

export let emptyUser: User = {
  mail: '',
  company: '',
  role: '',
  firstName: '',
  lastName: '',
  phone: '',
  unlockLevel: '0',
  areaOfResponsibility: '',
}
