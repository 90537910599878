import { SharedDataProvider } from '../../../../shared/providers/SharedDataProvider'
import 'regenerator-runtime'
import { User } from '../../../models/User'
import { ToastController } from '../../../../shared/controllers/ToastController'
import { AuthenticationController } from '../../../../shared/controllers/AuthenticationController'
import { RequestController } from '../../../../shared/controllers/RequestController'

export class UserPageController {
  sharedDataProvider: SharedDataProvider
  toastController: ToastController
  requestController: RequestController
  history: any

  constructor(history) {
    this.sharedDataProvider = new SharedDataProvider()
    this.toastController = new ToastController()
    this.requestController = new RequestController(history)
    this.history = history
  }

  public async getUserById(id): Promise<User> {
    let path = this.sharedDataProvider.host + '/users/getUserById?id=' + id
    let data = await this.requestController.getRequest(path)
    return data
  }

  public async saveUser(user: User, userProfilePicture, history) {
    let userObject = {
      user: user,
      userProfilePicture: userProfilePicture,
    }
    let path = this.sharedDataProvider.host + '/users/saveUser'
    let data = await this.requestController.postRequest(path, userObject)

    // show toast
    this.toastController.showToast_saveUser(user)

    this.history.push(`/user/${user._id}`)
  }

  public async deleteUser(user: User, history) {
    let path = this.sharedDataProvider.host + '/users/deleteUser?id=' + user._id
    let data = await this.requestController.getRequest(path)

    // show toast
    this.toastController.showToast_deleteUser(user)

    this.history.push(`/users`)
  }

  public async makeUserTheContactPerson(user: User, history) {
    let path =
      this.sharedDataProvider.host +
      '/users/makeUserTheContactPerson?id=' +
      user._id
    let data = await this.requestController.getRequest(path)

    // show toast
    this.toastController.showToast_makeUserTheContactPerson(user)

    //this.history.push(`/user/${user._id}`)
  }
}
