import { Button, createStyles, makeStyles, Theme } from '@material-ui/core'
import React, { useState } from 'react'
import { User } from '../../../models/User'
import { Footer } from '../../../../shared/components/Footer'
import { Header } from '../../../../shared/components/Header'
import { LoadingAnimation } from '../../../../shared/components/LoadingAnimation'
import { UserSearchController } from '../controllers/UserSearchController'
import { UserSearchResult } from './UserSearchResult'
import { SearchBar } from './SearchBar'

export const UserSearch = (props) => {
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      appContent: {
        minHeight: '86vh',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        flexWrap: 'wrap',
        alignItems: 'center',
      },
      seperator: {
        borderBottom: '1px solid lightgrey',
        marginTop: '10px',
        marginBottom: '10px',
      },
      dataContainer: {
        marginTop: 30,
        marginLeft: 20,
        marginRight: 20,
      },
      textfield: {
        margin: 0,
        width: 200,
        backgroundColor: 'white',
      },
      button: {
        width: 100,
        borderRadius: '10px',
        backgroundColor: '#1c8033',
        color: 'white',
        variant: 'contained',
        margin: 5,
        fontSize: 15,
        '&:hover': {
          backgroundColor: '#1c8033',
        },
      },
      buttonLoadMore: {
        backgroundColor: '#ffffff',
        color: 'black',
        variant: 'contained',
        padding: 10,
        marginTop: 10,
        '&:hover': {
          backgroundColor: '#eeeeee',
        },
      },
      searchResultMainData: {
        color: '#212121',
        fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
        fontSize: 16,
        textAlign: 'left',
      },
      searchResults: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'flex-start',
        marginBottom: 20,
      },
    })
  )
  const classes = useStyles()

  const userSearchController: UserSearchController = new UserSearchController(
    props.history
  )

  const [users, setUsers] = useState<User[]>([])
  const [isLoading, setIsLoading] = useState(true)

  const [latestSearchValue, setLatestSearchValue] = useState('')
  const [latestSearchProperty, setLatestSearchProperty] = useState('')
  let [searchIndexFrom, setSearchIndexFrom] = React.useState(0)
  let [searchIndexTo, setSearchIndexTo] = React.useState(20)
  let [
    totalNumberOfSearchResults,
    setTotalNumberOfSearchResults,
  ] = React.useState(0)

  async function getBatchOfUsers(
    searchValue: string,
    searchProperty: string,
    newSearch: Boolean
  ) {
    if (newSearch === true) {
      setIsLoading(true)
      let searchResult = await userSearchController.searchForUsers(
        searchValue,
        searchProperty,
        0,
        20
      )

      setSearchIndexFrom(0)
      setSearchIndexTo(20)

      let users = searchResult.users
      let totalNumberOfSearchResults = searchResult.totalNumberOfSearchResults

      setUsers(users)
      setTotalNumberOfSearchResults(totalNumberOfSearchResults)
      setLatestSearchValue(searchValue)
      setLatestSearchProperty(searchProperty)
    } else {
      let searchResult = await userSearchController.searchForUsers(
        searchValue,
        searchProperty,
        searchIndexFrom + 20,
        searchIndexTo + 20
      )

      setSearchIndexFrom(searchIndexFrom + 20)
      setSearchIndexTo(searchIndexTo + 20)

      let nextUsers = searchResult.users

      setUsers([...users, ...nextUsers])
    }

    setIsLoading(false)
  }

  return (
    <div>
      <Header
        page={'users'}
        history={props.history}
        selectedLanguage={props.selectedLanguage}
        setSelectedLanguage={props.setSelectedLanguage}
      />

      <div className={classes.appContent}>
        <div className={classes.dataContainer}>
          <SearchBar
            getBatchOfUsers={getBatchOfUsers}
            history={props.history}
            selectedLanguage={props.selectedLanguage}
          />
        </div>

        {isLoading ? (
          <div className={classes.dataContainer}>
            <LoadingAnimation />
          </div>
        ) : (
          <div className={classes.dataContainer}>
            <div className={classes.dataContainer}>
              <div className={classes.searchResultMainData}>
                {totalNumberOfSearchResults}
                {
                  {
                    de: ' Ergebnisse',
                    en: ' Results',
                    fr: ' Ergebnisse - FR',
                  }[props.selectedLanguage]
                }
              </div>
            </div>
            <div className={classes.seperator}></div>
            <div className={classes.dataContainer}>
              <div className={classes.searchResults}>
                {users.map((user) => (
                  <UserSearchResult user={user} />
                ))}

                {searchIndexTo < totalNumberOfSearchResults ? (
                  <Button
                    className={classes.buttonLoadMore}
                    onClick={() => {
                      getBatchOfUsers(
                        latestSearchValue,
                        latestSearchProperty,
                        false
                      )
                    }}
                  >
                    {
                      {
                        de: 'mehr laden',
                        en: 'load more',
                        fr: 'mehr laden - FR',
                      }[props.selectedLanguage]
                    }
                  </Button>
                ) : null}
              </div>
            </div>
          </div>
        )}
      </div>

      <Footer
        history={props.history}
        selectedLanguage={props.selectedLanguage}
      />
    </div>
  )
}
