import { makeStyles, Theme, createStyles } from '@material-ui/core'
import React from 'react'
import { Link } from 'react-router-dom'
import { SharedDataProvider } from '../../../../../shared/providers/SharedDataProvider'
import { MachineType } from '../../../../models/MachineType'

interface MachineTypeSearchResultProps {
  machineType: MachineType
  companyId: string
}

export const MachineTypeSearchResult = ({
  machineType,
  companyId,
}: MachineTypeSearchResultProps) => {
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      searchResult: {
        backgroundColor: 'ffffff',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        borderRadius: '5px',
        width: 240,
        margin: 5,
        overflow: 'hidden',
        boxShadow:
          '0 5px 5px 0 rgba(0, 0, 0, 0.25), 0 5px 5px 0 rgba(0, 0, 0, 0.025)',
      },
      searchResultTop: {
        display: 'flex',
        flexDirection: 'column',
      },
      companyLogo: {
        objectFit: 'contain',
        objectPosition: 'center',
        height: '160px',
        width: '240px',
      },
      searchResultBottom: {
        display: 'flex',
        margin: 5,
        flexDirection: 'column',
        position: 'relative',
      },
      searchResultMainData: {
        color: '#212121',
        fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
        fontSize: 16,
        textAlign: 'left',
      },
      searchResultOtherData: {
        color: '#212121',
        fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
        fontSize: 12,
        textAlign: 'left',
      },
      userProfilePicture: {
        objectFit: 'contain',
        objectPosition: 'center',
        width: '200px',
        borderRadius: '50%',
      },
    })
  )
  const classes = useStyles()

  const sharedDataProvider = new SharedDataProvider()

  return (
    <Link
      key={machineType._id}
      to={`${companyId}/${machineType._id}`}
      className={classes.searchResult}
      style={{ textDecoration: 'none' }}
    >
      <div className={classes.searchResultTop}>
        <img
          className={classes.userProfilePicture}
          key={machineType._id}
          src={
            sharedDataProvider.host +
            '/machineTypes/getMachineTypePicture?id=' +
            machineType._id +
            '&' +
            new Date()
          }
        ></img>
      </div>
      <div className={classes.searchResultBottom}>
        <div className={classes.searchResultMainData}>{machineType.name}</div>
      </div>
    </Link>
  )
}
