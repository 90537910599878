import { SharedDataProvider } from '../../../../shared/providers/SharedDataProvider'
import 'regenerator-runtime'
import { MachineType } from '../../../models/MachineType'
import { ToastController } from '../../../../shared/controllers/ToastController'
import { Company } from '../../../models/Company'
import { AuthenticationController } from '../../../../shared/controllers/AuthenticationController'
import { RequestController } from '../../../../shared/controllers/RequestController'

export class MachineTypePageController {
  sharedDataProvider: SharedDataProvider
  toastController: ToastController
  requestController: RequestController
  history: any

  constructor(history) {
    this.sharedDataProvider = new SharedDataProvider()
    this.toastController = new ToastController()
    this.requestController = new RequestController(history)
    this.history = history
  }

  public async getMachineTypeById(machineTypeId): Promise<MachineType> {
    let path =
      this.sharedDataProvider.host +
      '/machineTypes/getMachineTypeById?id=' +
      machineTypeId
    let data = await this.requestController.getRequest(path)
    return data
  }

  public async saveMachineType(
    machineType: MachineType,
    machineTypePicture,
    history
  ) {
    let machineTypeObject = {
      machineType: machineType,
      machineTypePicture: machineTypePicture,
    }
    let path = this.sharedDataProvider.host + '/machineTypes/saveMachineType'
    let data = await this.requestController.postRequest(path, machineTypeObject)

    // show toast
    this.toastController.showToast_saveMachineType(machineType)

    this.history.push(`/machineType/${machineType._id}`)
  }

  public async deleteMachineType(machineType: MachineType, history) {
    let path =
      this.sharedDataProvider.host +
      '/machineTypes/deleteMachineType?id=' +
      machineType._id
    let data = await this.requestController.getRequest(path)

    // show toast
    this.toastController.showToast_deleteMachineType(machineType)

    this.history.push(`/machineTypes/${machineType.company}`)
  }
}
