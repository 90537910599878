import {
  Button,
  createStyles,
  makeStyles,
  TextField,
  Theme,
} from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { Footer } from '../../../../shared/components/Footer'
import { Header } from '../../../../shared/components/Header'
import { LoadingAnimation } from '../../../../shared/components/LoadingAnimation'
import { SharedDataProvider } from '../../../../shared/providers/SharedDataProvider'
import { SharedStyleProvider } from '../../../../shared/providers/SharedStyleProvider'
import { emptyUser, User } from '../../../models/User'
import { ChangePasswordController } from '../controllers/ChangePasswordController'

export const ChangePassword = (props) => {
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      textfield: {
        margin: 0,
        backgroundColor: 'white',
        width: 200,
      },
      appContent: {
        minHeight: '86vh',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        flexWrap: 'wrap',
        alignItems: 'center',
      },
      dataContainer: {
        marginTop: 30,
        marginLeft: 20,
        marginRight: 20,
      },
      button: {
        width: 200,
        borderRadius: '10px',
        backgroundColor: '#1c8033',
        color: 'white',
        variant: 'contained',
        margin: 5,
        fontSize: 15,
        '&:hover': {
          backgroundColor: '#1c8033',
        },
      },
    })
  )
  const classes = useStyles()

  const sharedDataProvider = new SharedDataProvider()

  const changePasswordController: ChangePasswordController = new ChangePasswordController(
    props.history
  )

  let [oldPassword, setOldPassword] = useState('')
  let [newPassword, setNewPassword] = useState('')

  return (
    <div>
      <Header
        page={'changePassword'}
        history={props.history}
        selectedLanguage={props.selectedLanguage}
        setSelectedLanguage={props.setSelectedLanguage}
      />

      <div className={classes.appContent}>
        <div className={classes.dataContainer}>
          <TextField
            className={classes.textfield}
            size="small"
            id="oldPassword"
            label="Old Password"
            variant="filled"
            type="password"
            defaultValue={oldPassword}
            onBlur={(e) => setOldPassword(e.target.value as string)}
          />
        </div>
        <div className={classes.dataContainer}>
          <TextField
            className={classes.textfield}
            size="small"
            id="newPassword"
            label="New Password"
            variant="filled"
            type="password"
            defaultValue={newPassword}
            onBlur={(e) => setNewPassword(e.target.value as string)}
          />
        </div>
        <div className={classes.dataContainer}>
          <Button
            className={classes.button}
            onClick={() =>
              changePasswordController.changePassword(oldPassword, newPassword)
            }
          >
            {
              {
                de: 'Passwort ändern',
                en: 'Change Password',
                fr: 'Passwort ändern - FR',
              }[props.selectedLanguage]
            }
          </Button>
        </div>
      </div>

      <Footer
        history={props.history}
        selectedLanguage={props.selectedLanguage}
      />
    </div>
  )
}
