import React, { Component, useEffect } from 'react'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom'

export const ProtectedRoute = ({
  selectedLanguage,
  setSelectedLanguage,
  component: Component,
  ...props
}) => {
  return (
    <Route
      {...props}
      render={(props) =>
        window.localStorage.getItem('accesstoken') === '' ? (
          <Redirect to="/login" />
        ) : (
          <Component
            {...props}
            selectedLanguage={selectedLanguage}
            setSelectedLanguage={setSelectedLanguage}
          />
        )
      }
    />
  )
}
