import { createStyles, makeStyles, Theme } from '@material-ui/core'
import React from 'react'
import { Footer } from '../../shared/components/Footer'
import { Header } from '../../shared/components/Header'

export const Contact = (props) => {
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      appContent: {
        minHeight: '86vh',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        flexWrap: 'wrap',
        alignItems: 'center',
      },
      dataContainer: {
        marginTop: 30,
        marginLeft: 20,
        marginRight: 20,
      },
      heading: {
        color: '#212121',
        fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
        fontSize: 20,
        textAlign: 'left',
      },
    })
  )
  const classes = useStyles()

  return (
    <div>
      <Header
        page={'contact'}
        history={props.history}
        selectedLanguage={props.selectedLanguage}
        setSelectedLanguage={props.setSelectedLanguage}
      />

      <div className={classes.appContent}>
        <div className={classes.dataContainer}>
          <div className={classes.heading}>
            {
              {
                de: 'Kontakt',
                en: 'Contact',
                fr: 'Kontakt - FR',
              }[props.selectedLanguage]
            }
          </div>
        </div>
      </div>

      <Footer
        history={props.history}
        selectedLanguage={props.selectedLanguage}
      />
    </div>
  )
}
