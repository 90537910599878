import { Button, createStyles, makeStyles, Theme } from '@material-ui/core'
import { PhotoCamera } from '@material-ui/icons'
import React, { useEffect, useState } from 'react'
import { Footer } from '../../../../shared/components/Footer'
import { Header } from '../../../../shared/components/Header'
import { LoadingAnimation } from '../../../../shared/components/LoadingAnimation'
import { SharedDataProvider } from '../../../../shared/providers/SharedDataProvider'
import { MachinePageController } from '../controllers/MachinePageController'
import { ImageController } from '../../../../shared/controllers/ImageController'
import { Machine, emptyMachine } from '../../../models/Machine'
import { MachineMainData } from '../../../shared/components/MachineMainData'
import { CompanyAssignmentController } from '../../../../users/shared/controllers/CompanyAssignmentController'
import { AccessManagement } from './accessManagement'
import { User } from '../../../../users/models/User'
import { MachinePicture } from '../../../shared/components/MachinePicture'
import { Company, emptyCompany } from '../../../models/Company'
import { MachineType, emptyMachineType } from '../../../models/MachineType'
import { FileManager } from '../../../../shared/components/fileManager'
import { TermsController } from '../../../../shared/controllers/ TermsController'

export const MachinePage = (props) => {
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      appContent: {
        minHeight: '86vh',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        flexWrap: 'wrap',
        alignItems: 'center',
      },
      dataContainer: {
        marginTop: 30,
        marginLeft: 20,
        marginRight: 20,
      },
      button: {
        width: '100%',
        borderRadius: '10px',
        backgroundColor: '#1c8033',
        color: 'white',
        variant: 'contained',
        margin: 5,
        fontSize: 15,
        '&:hover': {
          backgroundColor: '#1c8033',
        },
      },
      deleteButton: {
        width: '100%',
        borderRadius: '10px',
        backgroundColor: '#f44336',
        color: 'white',
        variant: 'contained',
        margin: 5,
        fontSize: 15,
        '&:hover': {
          backgroundColor: '#d32215',
        },
      },
      machinePicture: {
        objectFit: 'contain',
        objectPosition: 'center',
        width: '240px',
        borderRadius: '50%',
      },
      hiddenInput: {
        display: 'none',
      },
    })
  )
  const classes = useStyles()

  const sharedDataProvider = new SharedDataProvider()

  const machinePageController: MachinePageController = new MachinePageController(
    props.history
  )

  const [isLoading, setIsLoading] = useState(true)
  let [machine, setMachine] = useState<Machine>({ ...emptyMachine })
  const [machineType, setMachineType] = useState<MachineType>(emptyMachineType)
  let [machinePicture, setMachinePicture] = useState<string | ArrayBuffer>()
  const [usersWithAccess, setUsersWithAccess] = useState<User[]>([])

  const change = (key: string, value: string) => {
    machine[key] = value
    setMachine({ ...machine })
  }

  useEffect(() => {
    setIsLoading(true)
    // fetch machine and more data from backend
    const { params } = props.match
    let getData = async () => {
      // get the machine by id
      let machine: Machine = await machinePageController.getMachineById(
        params.machineId
      )
      setMachine({ ...machine })

      let machineType: MachineType = await machinePageController.getMachineTypeById(
        machine.machineType
      )
      setMachineType({ ...machineType })

      setIsLoading(false)
    }
    getData()
  }, [])

  const termsController = new TermsController()

  return (
    <div>
      <Header
        page={'machine'}
        history={props.history}
        selectedLanguage={props.selectedLanguage}
        setSelectedLanguage={props.setSelectedLanguage}
      />

      {isLoading ? (
        <div className={classes.appContent}>
          <div className={classes.dataContainer}>
            <LoadingAnimation />
          </div>
        </div>
      ) : (
        <div className={classes.appContent}>
          <MachinePicture
            machine={machine}
            machinePicture={machinePicture}
            setMachinePicture={setMachinePicture}
            selectedLanguage={props.selectedLanguage}
          />

          <MachineMainData
            machine={machine}
            change={change}
            machineType={machineType}
            selectedLanguage={props.selectedLanguage}
          />

          <FileManager
            heading={
              termsController.getTerm(
                'Maschinen Dateien',
                props.selectedLanguage
              ) ||
              {
                de: 'Maschinen Dateien',
                en: 'Machine Files',
                fr: 'Maschinen Dateien - FR',
              }[props.selectedLanguage]
            }
            basePath={['machines', machine._id, 'files']}
            id={machine._id}
            type={'machine'}
            history={props.history}
            selectedLanguage={props.selectedLanguage}
            showRenameOption={true}
            showDeleteOption={true}
            showAddOptions={true}
          />

          <FileManager
            heading={
              termsController.getTerm(
                'Maschinentyp Dateien',
                props.selectedLanguage
              ) ||
              {
                de: 'Maschinentyp Dateien',
                en: 'Machinetype Files',
                fr: 'Maschinentyp Dateien - FR',
              }[props.selectedLanguage]
            }
            basePath={['machineTypes', machineType._id, 'files']}
            id={machineType._id}
            type={'machineType'}
            history={props.history}
            selectedLanguage={props.selectedLanguage}
            showRenameOption={false}
            showDeleteOption={false}
            showAddOptions={false}
          />

          {window.localStorage.getItem('loggedInUserRole') === 'admin' ||
          window.localStorage.getItem('loggedInUserRole') ===
            'manufacturerAdmin' ||
          window.localStorage.getItem('loggedInUserRole') ===
            'manufacturerAdminLight' ? (
            <AccessManagement
              machine={machine}
              usersWithAccess={usersWithAccess}
              setUsersWithAccess={setUsersWithAccess}
              history={props.history}
              selectedLanguage={props.selectedLanguage}
            />
          ) : null}

          <div className={classes.dataContainer}>
            <Button
              className={classes.button}
              onClick={() => {
                // sync users with permission into machine object
                machine['usersWithAccess'] = usersWithAccess.map(
                  (user) => user._id
                )
                setMachine({ ...machine })
                // save machine
                machinePageController.saveMachine(
                  machine,
                  machinePicture,
                  props.history
                )
              }}
            >
              {
                {
                  de: 'speichern',
                  en: 'save',
                  fr: 'speichern - FR',
                }[props.selectedLanguage]
              }
            </Button>
          </div>

          {window.localStorage.getItem('loggedInUserRole') === 'admin' ||
          window.localStorage.getItem('loggedInUserRole') ===
            'manufacturerAdmin' ||
          window.localStorage.getItem('loggedInUserRole') ===
            'manufacturerAdminLight' ? (
            <div className={classes.dataContainer}>
              <Button
                className={classes.deleteButton}
                onClick={async () => {
                  let confirmation = await confirm('Maschine wirklich löschen?')
                  if (confirmation === true) {
                    machinePageController.deleteMachine(machine, props.history)
                  }
                }}
              >
                {
                  {
                    de: 'löschen',
                    en: 'delete',
                    fr: 'löschen - FR',
                  }[props.selectedLanguage]
                }
              </Button>
            </div>
          ) : null}
        </div>
      )}
      <Footer
        history={props.history}
        selectedLanguage={props.selectedLanguage}
      />
    </div>
  )
}
