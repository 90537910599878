import { Button, createStyles, makeStyles, Theme } from '@material-ui/core'
import { PhotoCamera } from '@material-ui/icons'
import React from 'react'
import { SharedDataProvider } from '../../../shared/providers/SharedDataProvider'
import { ImageController } from '../../../shared/controllers/ImageController'
import { User } from '../../models/User'

interface UserProfilePictureProps {
  user: User
  userProfilePicture
  setUserProfilePicture
  selectedLanguage
}

export const UserProfilePicture = ({
  user,
  userProfilePicture,
  setUserProfilePicture,
  selectedLanguage,
}: UserProfilePictureProps) => {
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      appContent: {
        minHeight: '86vh',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        flexWrap: 'wrap',
        alignItems: 'center',
      },
      dataContainer: {
        marginTop: 30,
        marginLeft: 20,
        marginRight: 20,
      },
      button: {
        width: '100%',
        borderRadius: '10px',
        backgroundColor: '#1c8033',
        color: 'white',
        variant: 'contained',
        margin: 5,
        fontSize: 15,
        '&:hover': {
          backgroundColor: '#1c8033',
        },
      },
      deleteButton: {
        width: '100%',
        borderRadius: '10px',
        backgroundColor: '#f44336',
        color: 'white',
        variant: 'contained',
        margin: 5,
        fontSize: 15,
        '&:hover': {
          backgroundColor: '#d32215',
        },
      },
      machinePicture: {
        objectFit: 'contain',
        objectPosition: 'center',
        width: '240px',
        borderRadius: '50%',
      },
      hiddenInput: {
        display: 'none',
      },
      companyLogo: {
        objectFit: 'contain',
        objectPosition: 'center',
        height: '240px',
        width: '360px',
      },
      userProfilePicture: {
        objectFit: 'contain',
        objectPosition: 'center',
        width: '240px',
        borderRadius: '50%',
      },
    })
  )
  const classes = useStyles()

  const sharedDataProvider = new SharedDataProvider()
  const imageController: ImageController = new ImageController()

  async function imageUpload() {
    // get image
    let files = document.getElementById('userProfilePicture').files

    // transform image into base64
    // prepare image by resizing etc
    let file = await imageController.prepareImageForUploadSquared(files[0])
    let reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => {
      let base64 = reader.result
      setUserProfilePicture(base64)
    }
  }

  return (
    <div>
      <div className={classes.dataContainer}>
        <img
          className={classes.userProfilePicture}
          src={
            userProfilePicture
              ? userProfilePicture
              : sharedDataProvider.host +
                '/users/getUserProfilePicture?id=' +
                user._id +
                '&' +
                new Date()
          }
        ></img>

        <input
          className={classes.hiddenInput}
          type="file"
          id="userProfilePicture"
          accept="image/*"
          onChange={(e) => imageUpload()}
        />
      </div>

      <div className={classes.dataContainer}>
        <label htmlFor="userProfilePicture">
          <Button
            className={classes.button}
            component="span"
            startIcon={<PhotoCamera />}
          >
            {
              {
                de: 'Profilbild Uploaden',
                en: 'Upload Profilepicture',
                fr: 'Profilbild Uploaden - FR',
              }[selectedLanguage]
            }
          </Button>
        </label>
      </div>
    </div>
  )
}
