import { SharedDataProvider } from '../../../shared/providers/SharedDataProvider'
import 'regenerator-runtime'
import { AuthenticationController } from '../../../shared/controllers/AuthenticationController'
import { RequestController } from '../../../shared/controllers/RequestController'

export class CompanyAssignmentController {
  sharedDataProvider: SharedDataProvider
  requestController: RequestController

  constructor(history) {
    this.sharedDataProvider = new SharedDataProvider()
    this.requestController = new RequestController(history)
  }

  public async getAccessibleCompaniesAndIds() {
    let path =
      this.sharedDataProvider.host + '/companies/getAccessibleCompaniesAndIds'
    let accessibleCompaniesAndIds = await this.requestController.getRequest(
      path
    )
    return accessibleCompaniesAndIds
  }
}
