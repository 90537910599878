import { Button, createStyles, makeStyles, Theme } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { Machine } from '../../../machines/models/Machine'
import { File } from './File'
import { MachineFileController } from './MachineFileController'
import PublishIcon from '@material-ui/icons/Publish'
import { MachineTypeFileController } from './MachineTypeFileController'
import { LoadingAnimation } from '../LoadingAnimation'
import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import Typography from '@material-ui/core/Typography'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import {
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  Select,
  TextField,
  Menu,
  MenuItem,
} from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import GetAppIcon from '@material-ui/icons/GetApp'

declare module 'react' {
  interface InputHTMLAttributes<T> extends HTMLAttributes<T> {
    webkitdirectory?: string
  }
}

interface FileManagerProps {
  heading: string
  basePath: string[]
  type: string
  history: any
  id: string
  selectedLanguage
  showRenameOption: boolean
  showDeleteOption: boolean
  showAddOptions: boolean
}

export const FileManager = ({
  heading,
  basePath,
  type,
  history,
  id,
  selectedLanguage,
  showRenameOption,
  showDeleteOption,
  showAddOptions,
}: FileManagerProps) => {
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      backLink: {
        cursor: 'pointer',
        textDecoration: 'underline',
        color: 'black',
        fontSize: 16,
      },
      breadcrumbsContainerInner: {
        display: 'flex',
      },
      breadcrumbsContainer: {
        display: 'flex',
        alignItems: 'center',
        marginLeft: 'auto',
        justifyContent: 'space-between',
        padding: '10px',
        backgroundColor: 'lightgray',
      },
      buttonDataContainer: {
        display: 'flex',
        alignItems: 'center',
        marginLeft: 'auto',
      },
      buttonWrapper: {
        flex: 1,
        margin: 5,
      },
      filemanager: {
        backgroundColor: 'white',
        marginTop: '50px',
        marginBottom: '50px',
        width: '100%',
        borderRadius: '10px',
      },
      appContent: {
        minHeight: '86vh',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        flexWrap: 'wrap',
        alignItems: 'center',
      },
      dataContainer: {
        padding: '20px',
      },
      flexCenter: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      },
      filesDataContainer: {
        height: '30vh',
        overflowY: 'auto',
        padding: '10px',
      },
      button: {
        width: '99%',
        borderRadius: '10px',
        backgroundColor: '#1c8033',
        color: 'white',
        variant: 'contained',
        margin: 5,
        fontSize: 15,
        '&:hover': {
          backgroundColor: '#1c8033',
        },
      },
      marginBottomTop: {
        marginBottom: '20px',
        marginTop: '20px',
      },
      deleteButton: {
        width: '100%',
        borderRadius: '10px',
        backgroundColor: '#f44336',
        color: 'white',
        variant: 'contained',
        margin: 5,
        fontSize: 15,
        '&:hover': {
          backgroundColor: '#d32215',
        },
      },
      actionsButton: {
        backgroundColor: 'white',
        color: 'gray',
        fontSize: 25,
        borderRadius: '5px',
        border: '1px solid lightgray',
        marginLeft: '10px',
        height: '50px',
        '&:hover': {},
      },
      machinePicture: {
        objectFit: 'contain',
        objectPosition: 'center',
        width: '240px',
        borderRadius: '50%',
      },
      hiddenInput: {
        display: 'none',
      },
      seperator: {
        marginTop: '10px',
        marginBottom: '10px',
      },
      headingExpanded: {
        color: 'white',
        fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
        fontSize: 18,
        textAlign: 'center',
        marginBottom: 5,
        marginTop: 5,
      },
      headingNotExpanded: {
        color: 'black',
        fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
        fontSize: 18,
        textAlign: 'center',
        marginBottom: 5,
        marginTop: 5,
      },
      header: {
        width: '100%',
      },
      accordion: {
        marginTop: '30px',
        boxShadow: 'none',
        minWidth: '80%',
      },
      accordionSummaryExpanded: {
        color: 'white',
        backgroundColor: '#1c8033',
        borderTopLeftRadius: '10px',
        borderTopRightRadius: '10px',
        border: '1px solid lightgray',
      },
      accordionSummaryNotExpanded: {
        color: 'black',
        backgroundColor: 'white',
        borderTopLeftRadius: '10px',
        borderTopRightRadius: '10px',
        border: '1px solid lightgray',
      },
      accordionDetails: {
        borderLeft: '1px solid lightgray',
        borderRight: '1px solid lightgray',
        borderBottom: '1px solid lightgray',
        width: '100%',
        padding: '0px',
      },
      breadCrumbsInner: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
      details: {
        width: '100%',
      },
      fileTable: {
        width: '100%',
        padding: '10px',
        borderCollapse: 'collapse',
      },
      fileHead: {
        borderBottom: '2px solid black',
      },
      th: {
        padding: '0px',
        paddingBottom: '5px',
        textAlign: 'left',
      },
      arrow: {
        paddingLeft: '10px',
        paddingRight: '10px',
      },
      label: {
        display: 'flex',
      },
      loadingContainer: {
        display: 'flex',
        flexDirection: 'column',
      },
      loadingMessage: {
        paddingTop: '10px',
      },
    })
  )
  const classes = useStyles()

  const machineFileController: MachineFileController = new MachineFileController(
    history
  )
  const machineTypeFileController: MachineTypeFileController = new MachineTypeFileController(
    history
  )

  const [isLoading, setIsLoading] = useState(true)
  const [loadingMessage, setLoadingMessage] = useState('')
  let [path, setPath] = useState<string[]>(basePath)
  let [files, setFiles] = useState<string[]>([])

  const [expanded, setExpanded] = useState(false)

  const handleChange = (event, isExpanded) => {
    setExpanded(isExpanded)
  }

  const [showMenu, setShowMenu] = React.useState<null | HTMLElement>(null)
  const [
    showDownloadMenu,
    setShowDownloadMenu,
  ] = React.useState<null | HTMLElement>(null)

  const openMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setShowMenu(event.currentTarget)
  }
  const closeMenu = () => {
    setShowMenu(null)
  }

  const openDownloadMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setShowDownloadMenu(event.currentTarget)
  }
  const closeDownloadMenu = () => {
    setShowDownloadMenu(null)
  }

  async function directFileUpload(event) {
    setLoadingMessage('Uploading...')
    setIsLoading(true)

    const files = event.target.files
    const fileInfos = Array.from(files).map((file) => {
      const fullPath = (file as File).webkitRelativePath || (file as File).name
      return { file: file, path: fullPath }
    })

    // Extrahiere die Pfade, um Ordner zu erstellen
    const directoryPaths = new Set<string>()
    fileInfos.forEach((info) => {
      const pathParts = info.path.split('/')
      pathParts.pop() // Entferne den Dateinamen
      if (pathParts.length > 0) {
        // Es gibt einen Ordnerpfad
        directoryPaths.add(pathParts.join('/'))
      }
    })

    // Sortiere und erstelle die Ordner
    const sortedDirectoryPaths = Array.from(directoryPaths).sort(
      (a, b) => a.split('/').length - b.split('/').length
    )
    for (const path of sortedDirectoryPaths) {
      const pathParts = path.split('/')
      const folderName = pathParts.pop()
      const folderPath = [...basePath, ...pathParts]
      await createNewFolder(folderPath, folderName)
    }

    // Lade die Dateien hoch
    await uploadFiles(fileInfos)

    setIsLoading(false)
  }

  async function directFolderUpload(event) {
    setLoadingMessage('Uploading...')
    setIsLoading(true)

    const files = event.target.files
    const fileInfos = Array.from(files).map((file) => {
      const fullPath = (file as File).webkitRelativePath || (file as File).name
      return { file: file, path: fullPath }
    })

    // Extrahiere die Pfade, um Ordner zu erstellen
    const directoryPaths = new Set<string>()
    fileInfos.forEach((info) => {
      const pathParts = info.path.split('/')
      pathParts.pop() // Entferne den Dateinamen
      pathParts.reduce((acc, curr) => {
        const folderPath = acc ? `${acc}/${curr}` : curr
        directoryPaths.add(folderPath)
        return folderPath
      }, '')
    })

    // Sortiere und erstelle die Ordner
    const sortedDirectoryPaths = Array.from(directoryPaths).sort(
      (a, b) => a.split('/').length - b.split('/').length
    )
    for (const path of sortedDirectoryPaths) {
      const pathParts = path.split('/')
      const folderName = pathParts.pop()
      const folderPath = [...basePath, ...pathParts]
      await createNewFolder(folderPath, folderName)
    }

    const filteredFileInfos = fileInfos.filter(
      (info) => !info.path.endsWith('.DS_Store')
    )

    // Lade die Dateien hoch
    await uploadFiles(filteredFileInfos)

    setIsLoading(false)
  }

  async function uploadFiles(fileInfos) {
    const promises = []

    fileInfos.forEach((fileInfo) => {
      const fullPath = fileInfo.path
      const reader = new FileReader()
      reader.readAsDataURL(fileInfo.file)
      promises.push(
        new Promise<void>((resolve, reject) => {
          reader.onload = async () => {
            const base64 = reader.result
            try {
              // Pfad aufspalten, um Ordnerstruktur zu erhalten
              const pathParts = fullPath.split('/')
              const fileName = pathParts.pop() // Letztes Element ist der Dateiname
              const filePath = pathParts.join('/')

              if (type === 'machine') {
                await machineFileController.uploadMachineFile(
                  [...path, ...pathParts],
                  base64,
                  fileName,
                  id
                )
              } else if (type === 'machineType') {
                await machineTypeFileController.uploadMachineTypeFile(
                  [...path, ...pathParts],
                  base64,
                  fileName,
                  id
                )
              }
              resolve()
            } catch (error) {
              reject(error)
            }
          }
        })
      )
    })

    setLoadingMessage('Uploading...')
    setIsLoading(true)
    await Promise.all(promises)
    setIsLoading(false)
    fetchFiles() // Um die UI zu aktualisieren und die neuen Dateien/Ordner anzuzeigen.
  }

  async function createNewFolder(folderPath, folderName) {
    if (type === 'machine') {
      await machineFileController.createNewFolder(folderPath, id, folderName)
    } else if (type === 'machineType') {
      await machineTypeFileController.createNewFolder(
        folderPath,
        id,
        folderName
      )
    }
  }

  const handleDrop = async (e) => {
    e.preventDefault()

    if (!showAddOptions) return

    setLoadingMessage('Upload...')
    setIsLoading(true)

    const items = e.dataTransfer.items
    const directoryPaths = new Set<string>()
    const fileInfos = [] // Diese Liste wird Informationen zu jeder Datei speichern

    const traverseEntries = async (entry, path = ''): Promise<void> => {
      const fullPath = path ? `${path}/${entry.name}` : entry.name
      if (entry.isDirectory) {
        directoryPaths.add(fullPath)
        const reader = entry.createReader()
        return new Promise<void>((resolve) => {
          reader.readEntries(async (entries) => {
            for (const subEntry of entries) {
              await traverseEntries(subEntry, fullPath)
            }
            resolve() // Explizit als Promise<void>
          })
        })
      } else if (entry.isFile) {
        // Speichere Dateiinformationen statt nur den Pfad
        return new Promise<void>((resolve) => {
          entry.file((file) => {
            if (file.name !== '.DS_Store') {
              // Filtere .DS_Store Dateien heraus
              fileInfos.push({ file: file, path: fullPath })
            }
            resolve()
          })
        })
      }
    }

    // Verarbeite alle Einträge
    await Promise.all(
      Array.from(items).map(async (item) => {
        if (item.kind === 'file') {
          const entry = item.webkitGetAsEntry()
          if (entry) {
            await traverseEntries(entry)
          }
        }
      })
    )

    // Sortiere die Ordnerpfade, um sicherzustellen, dass sie in der richtigen Reihenfolge erstellt werden
    const sortedDirectoryPaths = Array.from(directoryPaths).sort(
      (a, b) => a.split('/').length - b.split('/').length
    )

    if (
      !(sortedDirectoryPaths.length === 1 && sortedDirectoryPaths[0] === '')
    ) {
      // Erstelle alle Ordner sequenziell
      await Promise.all(
        sortedDirectoryPaths.map(async (p) => {
          const fpSplit = p.split('/')
          const folderName = fpSplit[fpSplit.length - 1]
          const fp = fpSplit.slice(0, fpSplit.length - 1)
          const folderPath = [...path, ...fp]
          await createNewFolder(folderPath, folderName)
        })
      )
    }

    // Lade dann alle Dateien parallel hoch
    await uploadFiles(fileInfos)

    setIsLoading(false)
    setPath([...path])
  }

  const handleDragOver = (e) => {
    e.preventDefault() // Diese Zeile ist notwendig, um das standardmäßige Verhalten zu unterbinden.
  }

  const fetchFiles = async () => {
    setLoadingMessage('Load...')
    setIsLoading(true)
    if (type === 'machine') {
      const fs = await machineFileController.getMachineFiles(path, id)
      setFiles(fs)
    } else if (type === 'machineType') {
      const fs = await machineTypeFileController.getMachineTypeFiles(path, id)
      setFiles(fs)
    }
    setIsLoading(false)
  }

  const openFileInBrowser = (fileName: string) => {
    setLoadingMessage('Download...')
    setIsLoading(true)
    if (type === 'machine') {
      machineFileController.openMachineFile(path, fileName, id)
    } else if (type === 'machineType') {
      machineTypeFileController.openMachineTypeFile(path, fileName, id)
    }
    setIsLoading(false)
  }

  const downloadFile = async (fileName: string, otherPath?: string[]) => {
    setLoadingMessage('Download...')
    setIsLoading(true)
    if (type === 'machine') {
      await machineFileController.downloadMachineFile(
        otherPath ? otherPath : path,
        fileName,
        id
      )
    } else if (type === 'machineType') {
      await machineTypeFileController.downloadMachineTypeFile(
        otherPath ? otherPath : path,
        fileName,
        id
      )
    }
    setIsLoading(false)
  }

  const downloadPreviewImage = (fileName: string): any => {
    if (type === 'machine') {
      return machineFileController.getMachineFilePreviewImageUrl(
        path,
        fileName,
        id
      )
    } else if (type === 'machineType') {
      return machineTypeFileController.getMachineTypeFilePreviewImageUrl(
        path,
        fileName,
        id
      )
    }
  }

  const deleteFile = async (fileName: string) => {
    setLoadingMessage('Load...')
    setIsLoading(true)
    if (type === 'machine') {
      await machineFileController.deleteMachineFile(path, fileName, id)
    } else if (type === 'machineType') {
      await machineTypeFileController.deleteMachineTypeFile(path, fileName, id)
    }
    setPath([...path])
  }

  const renameFile = async (oldFileName: string, newFileName: string) => {
    setLoadingMessage('Load...')
    setIsLoading(true)
    if (type === 'machine') {
      await machineFileController.renameFile(path, oldFileName, newFileName, id)
    } else if (type === 'machineType') {
      await machineTypeFileController.renameFile(
        path,
        oldFileName,
        newFileName,
        id
      )
    }
    setPath([...path])
  }

  useEffect(() => {
    fetchFiles()
  }, [path])

  return (
    <Accordion className={classes.accordion} onChange={handleChange}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
        className={
          expanded
            ? classes.accordionSummaryExpanded
            : classes.accordionSummaryNotExpanded
        }
      >
        <Typography
          className={
            expanded ? classes.headingExpanded : classes.headingNotExpanded
          }
        >
          {heading}
        </Typography>
      </AccordionSummary>
      <AccordionDetails className={classes.accordionDetails}>
        <div
          className={classes.details}
          onDragOver={handleDragOver}
          onDrop={handleDrop}
        >
          <input
            className={classes.hiddenInput}
            type="file"
            id="fileUpload"
            multiple
            accept="*"
            onChange={(e) => directFileUpload(e)}
          />

          <input
            className={classes.hiddenInput}
            type="file"
            id="folderUpload"
            multiple
            webkitdirectory=""
            accept="*"
            onChange={(e) => directFolderUpload(e)}
          />

          <div className={classes.breadcrumbsContainer}>
            <div className={classes.breadcrumbsContainerInner}>
              <a
                onClick={() => {
                  const t = path.length - 2 - 1
                  for (let i = 0; i < t; i++) {
                    path.pop()
                  }
                  setPath([...path])
                }}
                className={classes.backLink}
              >
                {'HOME '}
              </a>

              {path.map((p, i) => {
                if (i < 3) return
                if (i < path.length) {
                  return (
                    <>
                      <div className={classes.arrow}>{'>'}</div>
                      <a
                        key={i}
                        onClick={() => {
                          const t = path.length - i - 1
                          for (let i = 0; i < t; i++) {
                            path.pop()
                          }
                          setPath([...path])
                        }}
                        className={classes.backLink}
                      >
                        {p}
                      </a>
                    </>
                  )
                } else {
                  return p
                }
              })}
            </div>

            <div>
              {showAddOptions ? (
                <Button
                  aria-controls="simple-menu"
                  aria-haspopup="true"
                  onClick={openMenu}
                  className={classes.actionsButton}
                >
                  +
                </Button>
              ) : (
                <></>
              )}

              <Menu
                id="simple-menu"
                anchorEl={showMenu}
                keepMounted
                open={Boolean(showMenu)}
                onClose={closeMenu}
              >
                <MenuItem
                  onClick={() => {
                    closeMenu()
                  }}
                >
                  <label htmlFor="fileUpload" className={classes.label}>
                    <PublishIcon />
                    {
                      {
                        de: 'Dateien Uploaden',
                        en: 'File Upload',
                        fr: 'Dateien Uploaden - FR',
                      }[selectedLanguage]
                    }
                  </label>
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    closeMenu()
                  }}
                >
                  <label htmlFor="folderUpload" className={classes.label}>
                    <PublishIcon />
                    {
                      {
                        de: 'Ordner Uploaden',
                        en: 'Folder Upload',
                        fr: 'Ordner Uploaden - FR',
                      }[selectedLanguage]
                    }
                  </label>
                </MenuItem>
                <MenuItem
                  onClick={async () => {
                    setLoadingMessage('Load...')
                    setIsLoading(true)
                    closeMenu()
                    await createNewFolder(path, '')
                    setPath([...path])
                  }}
                >
                  <AddIcon />
                  {
                    {
                      de: 'Ordner erstellen',
                      en: 'Create Folder',
                      fr: 'Ordner erstellen - FR',
                    }[selectedLanguage]
                  }
                </MenuItem>
              </Menu>

              <Button
                aria-controls="simple-menu"
                aria-haspopup="true"
                onClick={openDownloadMenu}
                className={classes.actionsButton}
              >
                <GetAppIcon />
              </Button>

              <Menu
                id="simple-menu"
                anchorEl={showDownloadMenu}
                keepMounted
                open={Boolean(showDownloadMenu)}
                onClose={closeDownloadMenu}
              >
                <MenuItem
                  onClick={() => {
                    downloadFile(
                      path[path.length - 1],
                      path.slice(0, path.length - 1)
                    )
                    closeDownloadMenu()
                  }}
                >
                  <GetAppIcon />
                  {
                    {
                      de: 'aktuellen Ordner downloaden',
                      en: 'download current folder',
                      fr: 'aktuellen Ordner downloaden - FR',
                    }[selectedLanguage]
                  }
                </MenuItem>
              </Menu>
            </div>
          </div>

          {isLoading ? (
            <div
              className={classes.filesDataContainer + ' ' + classes.flexCenter}
            >
              <div className={classes.loadingContainer}>
                <LoadingAnimation />
                <div className={classes.loadingMessage}>{loadingMessage}</div>
              </div>
            </div>
          ) : (
            <div
              className={
                classes.filesDataContainer +
                ' ' +
                (files.length === 0 ? classes.flexCenter : 0)
              }
            >
              {files.length === 0 ? (
                <a onClick={() => {}}>
                  {
                    {
                      de: 'Dieser Ordner ist leer',
                      en: 'This folder is empty',
                      fr: 'Dieser Ordner ist leer - FR',
                    }[selectedLanguage]
                  }
                </a>
              ) : (
                <table className={classes.fileTable}>
                  <thead className={classes.fileHead}>
                    <tr>
                      <th className={classes.th}>Typ</th>
                      <th className={classes.th}>Name</th>
                      <th className={classes.th}></th>
                      <th className={classes.th}>Größe</th>
                      <th className={classes.th}>Geändert</th>
                    </tr>
                  </thead>
                  <tbody>
                    {files.map((file, i) => (
                      <File
                        file={file}
                        path={path}
                        setPath={setPath}
                        index={i}
                        type={'machineFile'}
                        history={history}
                        selectedLanguage={selectedLanguage}
                        openFileInBrowser={openFileInBrowser}
                        downloadFile={downloadFile}
                        downloadPreviewImage={downloadPreviewImage}
                        deleteFile={deleteFile}
                        renameFile={renameFile}
                        showRenameOption={showRenameOption}
                        showDeleteOption={showDeleteOption}
                      />
                    ))}
                  </tbody>
                </table>
              )}
            </div>
          )}
        </div>
      </AccordionDetails>
    </Accordion>
  )
}
