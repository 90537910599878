import { Button, createStyles, makeStyles, Theme } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { Company } from '../../../../models/Company'
import { Footer } from '../../../../../shared/components/Footer'
import { Header } from '../../../../../shared/components/Header'
import { LoadingAnimation } from '../../../../../shared/components/LoadingAnimation'
import { CompanySearchResult } from './CompanySearchResult'
import { MachineSearchController } from '../../controllers/MachineSearchController'

export const MachineSearchCompanySelection = (props) => {
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      appContent: {
        minHeight: '86vh',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        flexWrap: 'wrap',
        alignItems: 'center',
      },
      seperator: {
        borderBottom: '1px solid lightgrey',
        marginTop: '10px',
        marginBottom: '10px',
      },
      dataContainer: {
        marginTop: 30,
        marginLeft: 20,
        marginRight: 20,
      },
      textfield: {
        margin: 0,
        width: 200,
        backgroundColor: 'white',
      },
      button: {
        width: 100,
        borderRadius: '10px',
        backgroundColor: '#1c8033',
        color: 'white',
        variant: 'contained',
        margin: 5,
        fontSize: 15,
        '&:hover': {
          backgroundColor: '#1c8033',
        },
      },
      buttonLoadMore: {
        backgroundColor: '#ffffff',
        color: 'black',
        variant: 'contained',
        padding: 10,
        marginTop: 10,
        '&:hover': {
          backgroundColor: '#eeeeee',
        },
      },
      searchResultMainData: {
        color: '#212121',
        fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
        fontSize: 16,
        textAlign: 'left',
      },
      searchResults: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'flex-start',
        marginBottom: 20,
      },
      heading: {
        color: '#212121',
        fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
        fontSize: 18,
        textAlign: 'center',
        marginBottom: 10,
      },
    })
  )
  const classes = useStyles()

  const machineSearchController: MachineSearchController = new MachineSearchController(
    props.history
  )

  const [companies, setCompanies] = useState<Company[]>([])
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    setIsLoading(true)
    let getAllCompanies = async () => {
      let companies = await machineSearchController.getAllCompanies()
      setCompanies([...companies])
      setIsLoading(false)
    }
    getAllCompanies()
  }, [])

  return (
    <div>
      <Header
        page={'machines'}
        history={props.history}
        selectedLanguage={props.selectedLanguage}
        setSelectedLanguage={props.setSelectedLanguage}
      />

      <div className={classes.appContent}>
        {isLoading ? (
          <div className={classes.dataContainer}>
            <LoadingAnimation />
          </div>
        ) : (
          <div className={classes.dataContainer}>
            <div className={classes.heading}>
              {
                {
                  de: 'Unternehmen auswählen',
                  en: 'Select a company',
                  fr: 'Unternehmen auswählen - FR',
                }[props.selectedLanguage]
              }
            </div>
            <div className={classes.dataContainer}>
              <div className={classes.searchResults}>
                {companies.map((company) => (
                  <CompanySearchResult company={company} />
                ))}
              </div>
            </div>
          </div>
        )}
      </div>

      <Footer
        history={props.history}
        selectedLanguage={props.selectedLanguage}
      />
    </div>
  )
}
