import { SharedDataProvider } from '../../../../shared/providers/SharedDataProvider'
import 'regenerator-runtime'
import { User } from '../../../models/User'
import { RequestController } from '../../../../shared/controllers/RequestController'
import { ToastController } from '../../../../shared/controllers/ToastController'

export class CodeGenerationController {
  sharedDataProvider: SharedDataProvider
  toastController: ToastController
  requestController: RequestController

  constructor(history) {
    this.sharedDataProvider = new SharedDataProvider()
    this.toastController = new ToastController()
    this.requestController = new RequestController(history)
  }

  public async getAccessibleCompaniesAndIds() {
    let path =
      this.sharedDataProvider.host + '/companies/getAccessibleCompaniesAndIds'
    let accessibleCompaniesAndIds = await this.requestController.getRequest(
      path
    )
    return accessibleCompaniesAndIds
  }

  public async getMachineTypeNumberMapByCompanyId(id) {
    let path =
      this.sharedDataProvider.host +
      '/machines/getMachineTypeNumberMapByCompanyId?id=' +
      id
    let machineTypeNumberMapByCompanyId = await this.requestController.getRequest(
      path
    )
    return machineTypeNumberMapByCompanyId
  }

  public async getCodeOptionsByCompanyId(id) {
    let path =
      this.sharedDataProvider.host +
      '/companies/getCodeOptionsByCompanyId?id=' +
      id
    let data = await this.requestController.getRequest(path)
    return data
  }

  public async generateCode(
    randomCode: string,
    option: string,
    machineType: string,
    machineNumber: string,
    notes: string,
    companyId: string
  ): Promise<string> {
    let path =
      this.sharedDataProvider.host +
      '/companies/generateCode?randomCode=' +
      randomCode +
      '&option=' +
      option +
      '&machineType=' +
      machineType +
      '&machineNumber=' +
      machineNumber +
      '&notes=' +
      notes +
      '&companyId=' +
      companyId
    let data = await this.requestController.getRequest(path)
    return data
  }
}
